import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchTokenMetaData } from './fetchTokens'
import { GeneralTokenListEntry, VersionedDeltaTokenList } from './reducer'
import { ChainIdMap } from 'utils/Types'

type TokenMapResponse = {
  result: { [chainId: number]: GeneralTokenListEntry },
  callSignature: string | undefined
}

type ChainIdsArray = number[]

interface TokenRequest {
  chainIds: ChainIdsArray
  forceFetch?: boolean | undefined
  tokenAdddress: string | undefined
}

export const fetchXChainTokens: AsyncThunk<TokenMapResponse, TokenRequest, any> =
  createAsyncThunk<
    TokenMapResponse,
    TokenRequest,
    {
      state: {
        lists: {
          callSignatures: string[],
          byChain: ChainIdMap<{ currentList: VersionedDeltaTokenList | null }>
        }
      }
    }
  >(
    'lists/fetchXChainTokens',
    async (params, { getState }) => {

      // only query relevant chainIds if provuided 
      if (!params.tokenAdddress || params.chainIds?.length === 0) {
        return {
          result: {},
          callSignature: undefined
        }
      }

      const callSignature = createCallSig(params.tokenAdddress, params.chainIds)
      if (getState().lists.callSignatures.includes(callSignature)) {
        return {
          result: {},
          callSignature: undefined
        }
      }

      const addressToQuery = params.tokenAdddress.toLowerCase()

      let promises: Promise<any>[] = []

      const lists = getState().lists.byChain
      const chainIds = Object.keys(lists ?? {})

      // check if the token already is an entry in our lists 
      const includedInAny = chainIds.map(
        cId => Object.keys(lists[cId].currentList.list).includes(addressToQuery) // check if address in in list
      ).some(x => x)

      // if it is already included directly return nothing
      if (includedInAny && !params.forceFetch) return {
        result: {},
        callSignature
      }
      // collect promises
      for (const chainId of params.chainIds) {
        const currentList = lists?.[chainId].currentList
        // we require the list to be loaded and that the token is NOT in the list
        if (currentList && !Object.keys(currentList.list).includes(addressToQuery))
          promises.push(
            fetchTokenMetaData(
              chainId,
              params.tokenAdddress
            )
          )
      }
      const st = performance.now()
      // resolve
      const resolved = await Promise.all(promises)
      // check time to fetch
      console.info("token", params.tokenAdddress, "took", performance.now() - st, "for", promises.length)

      let result = {}
      // map result
      resolved.map(entry => {

        if (entry.tokenData) {
          result[entry.chainId] = {
            chainId: entry.chainId,
            ...entry.tokenData
          }
        }
      })

      return {
        result,
        callSignature
      }
    }
  )

function createCallSig(token: string, chainIds: number[]) {
  return `${token.toLowerCase()}-${chainIds.sort((a, b) => a - b).join('-')}`
}