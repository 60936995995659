import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { Lender } from 'types/lenderData/base'
import { SupportedChainId } from 'constants/chains'
import { ChainIdMap } from 'utils/Types'
import { getLenderUserData } from '../fetchers/fetchUserData'
import { AaveV2UserReserveResponse } from '../fetchers/aave-v2-type/types'


interface AureliusUserReservesQueryParams {
  chainId: number
  account: string
}

export const fetchAureliusUserData: AsyncThunk<
  AaveV2UserReserveResponse,
  AureliusUserReservesQueryParams,
  any
> = createAsyncThunk<
  AaveV2UserReserveResponse,
  AureliusUserReservesQueryParams,
  {
    state: {
      oracles: {
        live: ChainIdMap<number>
      }
    },
  }>(
    'aurelius/fetchAureliusUserData',
    async ({ chainId, account }, { getState }) => {
      if (!account || chainId !== SupportedChainId.MANTLE) {
        return { chainId, tokensData: {}, rewards: {}, allowances: {}, account: '' }
      }

      // get fields from other slices
      const prices = getState().oracles.live
      try {
        const lenderData = await getLenderUserData(chainId, [Lender.AURELIUS], account, prices)
        return lenderData[Lender.AURELIUS]
      } catch (error) {
        console.error('Error fetching Aurelius user data:', error)
        return {
          data: {},
          config: {},
          chainId,
        }
      }
    }
  )
