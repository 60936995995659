import { SupportedAssets } from 'types/1delta'
import { Call } from 'utils/multicall'
import { getCompoundV3TokenAddresses } from 'hooks/lenders/lenderAddressGetter'
import { Lender } from 'types/lenderData/base'
import { ONE_DELTA_COMPOSER } from 'hooks/1delta/addresses1Delta'
import { addressesCompoundV3Core } from 'hooks/1delta/addressesCompoundV3'

export const buildCompoundV3UserCall = (chainId: number, lender: Lender, account: string): Call[] => {
  switch (lender) {
    case Lender.COMPOUND_V3: {
      const baseAsset = SupportedAssets.USDCE
      const rawAddressDict = getCompoundV3TokenAddresses(chainId)
      const cometContractAddress = addressesCompoundV3Core.comet[chainId][baseAsset]
      const names = Object.keys(rawAddressDict)
      const assetsNoBase = names.filter(a => a !== baseAsset)

      const allowedCall = {
        address: cometContractAddress,
        name: 'isAllowed',
        params: [account, ONE_DELTA_COMPOSER[chainId]],
      }

      const baseCalls = [
        {
          address: cometContractAddress,
          name: 'balanceOf',
          params: [account],
        },
        {
          address: cometContractAddress,
          name: 'borrowBalanceOf',
          params: [account],
        }
      ]

      const callsNonBase: Call[] = assetsNoBase.map((tk) => {
        return {
          address: cometContractAddress,
          name: 'userCollateral',
          params: [account, rawAddressDict[tk]],
        }
      })

      const callEarnedBalances = {
        address: addressesCompoundV3Core.cometRewards[chainId],
        name: 'getRewardOwed',
        params: [cometContractAddress, account],
      }
      const calls = [
        ...callsNonBase,
        ...baseCalls,
        allowedCall,
        callEarnedBalances
      ]
      return calls
    }
    default:
      return []
  }
}
