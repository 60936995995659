import { Text, HStack, Spinner, VStack, Flex, Accordion, useBreakpointValue } from '@chakra-ui/react'
import { useThemeColors, validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'
import { WalletMenuAssetsRow } from './WalletMenuAssetsRow'
import { NATIVE_ASSET, SupportedAssets } from 'types/1delta'
import { usePrices } from 'state/oracles/hooks'
import { getAvailableChainIds, SupportedChainId } from 'constants/chains'
import { CryptoPill } from 'components/Pills/CryptoPill'
import { useEffect, useMemo, useState } from 'react'
import { useUserTheme } from 'state/user/hooks'
import { useAllMainTokensPerChain } from 'hooks/Tokens'
import { useSubscribedXChainCurrencyBalances } from 'state/globalNetwork/hooks'
import { addressToAsset, groupAsset } from 'hooks/1delta/addressesTokens'
import { useNativeCurrencies } from 'lib/hooks/useNativeCurrency'
import { Currency } from '@1delta/base-sdk'
import { useHistory } from 'react-router-dom'
import { useWalletMenuDisclosure } from 'state/application/hooks'

export interface NewWalletAssetData {
  [chainId: number]: {
    currency: Currency | undefined
    price: number
    walletBalance: number
    walletBalanceUSD: number
  }[]
}

export interface MultichainAssetData {
  [assetId: string]: NewWalletAssetData
}

interface WalletMenuAssetsProps {
  account: string
  chainId: number
  setTotalBalance: (n: number) => void
}

export const WalletMenuAssets: React.FC<WalletMenuAssetsProps> = ({ account, chainId, setTotalBalance }) => {
  const availableNetworks = getAvailableChainIds()
  const allTokens = Object.values(useAllMainTokensPerChain(availableNetworks)).reduce(
    (a: any[], b) => [...a, ...Object.values(b)],
    []
  )
  const native = useNativeCurrencies()
  const nativeArray = Object.values(native)
  const { balances, isLoading: singleBalanceIsLoading } = useSubscribedXChainCurrencyBalances([
    ...nativeArray,
    ...allTokens,
  ])

  const hasBalances = !singleBalanceIsLoading && balances.slice(0, nativeArray.length).some(b => b?.greaterThan(0n))

  const nonZeroBalances = balances.filter((balance) => balance?.toExact() !== '0')
  // in supportedAssetsOfNonZeroBalances, USDC on Mantle is treated as USDC.E
  // for this reason, USDC balance on Mantle is not shown
  // this has to be fixed
  const supportedAssetsOfNonZeroBalances = nonZeroBalances.map((balance) => {
    return balance?.currency.isNative
      ? balance.currency.symbol
      : balance?.currency.chainId && balance?.currency.wrapped.address
        ? addressToAsset(balance?.currency.chainId, balance?.currency.wrapped.address)
        : undefined
  })
  const assetsPrices = usePrices(supportedAssetsOfNonZeroBalances)
  const assetToPrice = Object.fromEntries(
    supportedAssetsOfNonZeroBalances.map((asset, index) => [asset, assetsPrices[index]])
  )

  let xchainData: MultichainAssetData = {}

  nonZeroBalances.forEach((balance) => {
    const chainId = balance?.currency?.chainId || 0
    const asset = balance?.currency.isNative ? NATIVE_ASSET[chainId] : balance?.currency.wrapped.symbol
    const assetGroup = groupAsset(asset)
    if (asset) {
      const amount = parseFloat(balance?.toExact() ?? '0')
      const price = assetToPrice[asset.toUpperCase()] ?? 0
      const balanceUSD = amount * price || 0

      if (!xchainData[assetGroup]) {
        xchainData[assetGroup] = {}
      }

      if (!xchainData[assetGroup][chainId]) {
        xchainData[assetGroup][chainId] = []
      }
      xchainData[assetGroup][chainId].push({
        currency: balance?.currency,
        price,
        walletBalance: amount,
        walletBalanceUSD: balanceUSD,
      })
    }
  })

  // order chainData by totalWalletBalanceUSD
  const sortedXChainData = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(xchainData).sort(([, a], [, b]) => {
          const totalA = Object.values(a).reduce(
            (acc, chainData) => acc + chainData.reduce((a, b) => a + b.walletBalanceUSD, 0),
            0
          )
          const totalB = Object.values(b).reduce(
            (acc, chainData) => acc + chainData.reduce((a, b) => a + b.walletBalanceUSD, 0),
            0
          )
          return totalB - totalA
        })
      ),
    [xchainData]
  )

  // Calculate totalBalancePerChain
  const totalBalancePerChain: { [chainId: number]: number } = {}
  for (const assetChains of Object.values(sortedXChainData)) {
    for (const [chainId, assetData] of Object.entries(assetChains)) {
      totalBalancePerChain[+chainId] =
        (totalBalancePerChain[+chainId] || 0) + assetData.reduce((a, b) => a + (b?.walletBalanceUSD ?? 0), 0)
    }
  }

  const totalBalance = Object.values(totalBalancePerChain).reduce((a, b) => a + b, 0)
  useEffect(() => {
    if (account) {
      setTotalBalance(totalBalance)
    }
  }, [totalBalance, account])

  const chainsWithBalance = Object.entries(totalBalancePerChain)
    .sort(([, balanceA], [, balanceB]) => balanceB - balanceA)
    .map(([chainId]) => +chainId as SupportedChainId)

  const isSupported = getAvailableChainIds().includes(chainId)

  const [selectedChainIds, setSelectedChainIds] = useState<SupportedChainId[]>(chainsWithBalance)

  useEffect(() => {
    setSelectedChainIds(chainsWithBalance)
  }, [chainsWithBalance.length > 0])

  const allChainsSelected = selectedChainIds.length === chainsWithBalance.length

  const handleSelectChainId = (chainId: SupportedChainId) => {
    const newSelectedChainIds = allChainsSelected
      ? [chainId]
      : selectedChainIds.includes(chainId)
        ? selectedChainIds.length === 1
          ? chainsWithBalance
          : selectedChainIds.filter((id) => id !== chainId)
        : [...selectedChainIds, chainId]

    setSelectedChainIds(newSelectedChainIds)
  }

  const maxShowableChains = 7

  const [showAllChains, setShowAllChains] = useState(false)

  const displayedChains = showAllChains ? chainsWithBalance : chainsWithBalance.slice(0, maxShowableChains)

  const showAsset = (assetData: NewWalletAssetData) => {
    return Object.values(assetData).some((chainData) => chainData.some((x) => x?.walletBalance && x.walletBalance > 0))
  }

  const theme = useUserTheme()

  const scrollbarCss = useScrollbarCss()

  const maxHeight = useBreakpointValue({
    base: '57vh',
    sm: '57vh',
    md: '57vh',
    lg: '66vh',
  })

  const [accordionIndex, setAccordionIndex] = useState<number[]>([])

  const history = useHistory()

  const { onCloseWalletMenu } = useWalletMenuDisclosure()

  return !isSupported ? (
    /////////////////////////////////////
    // network not supported
    /////////////////////////////////////
    <HStack p="1rem">
      <Text
        style={getTypography('Typography/Body-Labels/Bold/Body-Label')}
        color={validateColor('Text/Headings & Titles/Title-text')}
      >
        Network not yet supported
      </Text>
    </HStack>
  ) : nonZeroBalances.length === 0 && singleBalanceIsLoading ? (
    /////////////////////////////////////
    // Data is loading
    /////////////////////////////////////
    <HStack p="1rem">
      <Text
        style={getTypography('Typography/Body-Labels/Bold/Body-Label')}
        color={validateColor('Text/Headings & Titles/Title-text')}
      >
        Loading
      </Text>
      <Spinner size={'sm'} color={validateColor('Text/Lables/Lable-success')} thickness="3px" />
    </HStack>
  ) : !hasBalances ? (
    /////////////////////////////////////
    // Not loadig anymore but asset length
    // is zero
    /////////////////////////////////////
    <HStack p="1rem">
      <Text
        style={getTypography('Typography/Body-Labels/Bold/Body-Label')}
        color={validateColor('Text/Headings & Titles/Title-text')}
      >
        No assets found
      </Text>
    </HStack>
  ) : (
    /////////////////////////////////////
    // Show content
    /////////////////////////////////////
    <VStack w="100%" gap="1rem" pb="1rem">
      <Flex gap="0.375rem" flexWrap="wrap" w="100%">
        <CryptoPill
          isSelected={allChainsSelected}
          onClick={
            allChainsSelected
              ? () => setSelectedChainIds([chainsWithBalance[0]])
              : () => setSelectedChainIds(chainsWithBalance)
          }
          color={validateColor('Text/Subheadings & Sub Titles/Sub-headings')}
          alignItems="flex-end"
        >
          <Text style={getTypography('Typography/Small/Normal/Small 1')}>All</Text>
          <Text style={getTypography('Typography/Small/Normal/Small 3')}>({chainsWithBalance.length})</Text>
        </CryptoPill>
        {displayedChains.map(
          (chainId) =>
            totalBalancePerChain[chainId] && (
              <CryptoPill
                key={chainId}
                chainId={chainId}
                text={totalBalancePerChain[chainId] ? `$${totalBalancePerChain[chainId].toFixed(2)}` : 'N/A'}
                isSelected={!allChainsSelected && selectedChainIds.includes(chainId)}
                onClick={() => handleSelectChainId(chainId)}
              />
            )
        )}
        {chainsWithBalance.length > maxShowableChains && (
          <CryptoPill
            text={showAllChains ? 'Show less' : 'Show all'}
            onClick={() => setShowAllChains(!showAllChains)}
            border="none"
            textProps={{ style: getTypography('Typography/Small/Normal/Small 1') }}
          />
        )}
      </Flex>
      <VStack
        as={Accordion}
        allowMultiple
        w="100%"
        gap="0.625rem"
        overflowY="auto"
        overflowX="hidden"
        maxH={maxHeight}
        css={scrollbarCss}
        index={accordionIndex}
      >
        {Object.entries(sortedXChainData).map(([assetId, assetData], assetRowIndex) => {
          return (
            showAsset(assetData) && (
              <WalletMenuAssetsRow
                key={assetId}
                assetId={assetId as SupportedAssets}
                assetData={assetData}
                selectedChainIds={selectedChainIds}
                theme={theme}
                accordionIndex={accordionIndex}
                setAccordionIndex={setAccordionIndex}
                assetRowIndex={assetRowIndex}
                history={history}
                onCloseWalletMenu={onCloseWalletMenu}
              />
            )
          )
        })}
      </VStack>
    </VStack>
  )
}

export const useScrollbarCss = () => {
  const [scrollbarTrackBg, scrollbarThumbBg, scrollbarBorder] = useThemeColors([
    'Surface/Surface-pre-primary',
    'Surface/Surface-secondary',
    'Surface/Surface-primary',
  ])

  return {
    '&::-webkit-scrollbar': {
      width: '1rem',
      backgroundColor: scrollbarTrackBg,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: scrollbarTrackBg,
      borderLeft: '7px solid',
      borderColor: scrollbarBorder,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: scrollbarThumbBg,
      borderLeft: '7px solid',
      borderColor: scrollbarBorder,
    },
  }
}
