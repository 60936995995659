import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { Lender } from 'types/lenderData/base'
import { ChainIdMap } from 'utils/Types'
import { AdditionalYields } from 'state/oracles/reducer'
import { getLenderPublicData } from '../fetchers/fetchLenderData'
import { InitGeneralPublicResponse, InitReservesQueryParams } from '../fetchers/init/types'

export const fetchInitPublicData: AsyncThunk<InitGeneralPublicResponse, InitReservesQueryParams, any> =
  createAsyncThunk<
    InitGeneralPublicResponse,
    InitReservesQueryParams,
    {
      state: {
        oracles: {
          live: ChainIdMap<number>
          additionalYields: AdditionalYields
        }
      },
    }
  >(
    'lender-init/fetchInitPublicData',
    async ({ chainId }, { getState }) => {
      // get fields from other slices
      const prices = getState().oracles.live
      const additionalYields = getState().oracles.additionalYields
      try {
        const lenderData = await getLenderPublicData(chainId, [Lender.INIT], prices, additionalYields)
        return lenderData[Lender.INIT]
      } catch (error) {
        console.error('Error fetching Init public data:', error)
        return {
          data: {},
          chainId,
        }
      }
    }
  )