import { createReducer } from '@reduxjs/toolkit'
import {
  resetMeridianUserState,
  resetState,
} from './actions'
import {
  fetchMeridianPublicData,
} from './fetchPublicData'
import { fetchMeridianUserData } from './fetchUserData'
import { fetchMeridianAllowances } from './fetchAllowances'
import { STATE_CHAINIDS } from 'constants/chains'
import { AaveV2State } from 'types/lenderData/aave-v2'

const emptyData = {
  publicLoaded: false,
  lenderData: {},
  userData: {},
  aprData: {},
  balanceData: {},
  lenderRewards: 0
}

export const initialState: AaveV2State = Object.assign(
  {}, ...STATE_CHAINIDS.map(cId => { return { [cId]: emptyData } })
)

export default createReducer<AaveV2State>(initialState, (builder) =>
  builder
    .addCase(resetState, () => initialState)
    .addCase(resetMeridianUserState, (state, action) => {
      const chainId = action.payload.chainId
      if (state[chainId])
        state[chainId].userData = {}
    })
    // new reserve data-fetch using aave data provider
    .addCase(fetchMeridianPublicData.fulfilled, (state, action) => {
      const assetKeys = Object.keys(action.payload.data)
      const chainId = action.payload.chainId
      if (assetKeys.length === 0) return; // prevents setting load flags
      if (!state[chainId]) state[chainId] = emptyData
      // assign public data
      for (let i = 0; i < assetKeys.length; i++) {
        const asset = assetKeys[i]
        state[chainId].lenderData[asset] = {
          ...state[chainId].lenderData[asset],
          ...action.payload.data[asset],
          ...action.payload.config[asset],
        }
      }
      state[chainId].publicLoaded = true
    })
    .addCase(fetchMeridianPublicData.pending, (state) => {
      //
    })
    // user data from provider
    .addCase(fetchMeridianUserData.fulfilled, (state, action) => {
      let assetKeys = Object.keys(action.payload.tokensData)
      const { chainId, account } = action.payload
      if (!state[chainId]) state[chainId] = emptyData

      // organic yields
      let depositInterest = 0
      let borrowInterest = 0
      // rewards
      let rewardDepositAccrual = 0
      let rewardBorrowAccrual = 0
      // staking
      let stakingDepositAccrual = 0
      let stakingBorrowAccrual = 0
      // amountrs
      let deposits = 0
      let debt = 0
      let collateral = 0
      let borrowDiscountedCollateral = 0
      if (!state[chainId]) state[chainId] = emptyData
      // main user data
      for (let i = 0; i < assetKeys.length; i++) {
        const asset = assetKeys[i]
        if (!state[chainId].lenderData[asset]) continue;
        const { depositsUSD, debtStableUSD, debtUSD, collateralActive } = action.payload.tokensData[asset]
        const { depositRate, stakingYield, variableBorrowRate, stableBorrowRate, rewards } = state[chainId].lenderData[asset]
        // amounts
        deposits += depositsUSD
        debt += debtStableUSD
        debt += debtUSD
        // rewards 
        Object.values(rewards ?? {}).map(rewardData => {
          rewardDepositAccrual += rewardData.depositRate * depositsUSD
          rewardBorrowAccrual += rewardData.variableBorrowRate * debtUSD + rewardData.stableBorrowRate * debtStableUSD
        })
        // staking
        stakingDepositAccrual += (stakingYield ?? 0) * depositsUSD
        stakingBorrowAccrual += (stakingYield ?? 0) * (debtStableUSD + debtUSD)
        if (collateralActive) {
          // risk adjusted
          collateral += (state[chainId].lenderData[asset].config[0]?.collateralFactor ?? 1) * depositsUSD
          borrowDiscountedCollateral += (state[chainId].lenderData[asset].config[0]?.borrowCollateralFactor ?? 1) * depositsUSD
        }
        // IRs
        depositInterest += depositRate * depositsUSD
        borrowInterest += debtStableUSD * stableBorrowRate + debtUSD * variableBorrowRate

        if (!state[chainId].userData[account]) state[chainId].userData[account] = {}
        // add data to the state
        state[chainId].userData[account][asset] = {
          // ...state[chainId].userData[account][asset],
          ...action.payload.tokensData[asset],
          ...action.payload.allowances[asset]
        }
      }
      const nav = deposits - debt
      // aggregated balance data
      state[chainId].balanceData = {
        ...state[chainId].balanceData,
        [account]: {
          borrowDiscountedCollateral,
          collateral,
          deposits,
          debt,
          adjustedDebt: debt,
          nav,
          rewards: action.payload.rewards,
        }
      }
      if (!state[chainId]?.aprData) state[chainId].aprData = {}
      // aggregated apr data
      state[chainId].aprData[account] = {
        apr: (depositInterest - borrowInterest) / nav,
        borrowApr: borrowInterest / debt,
        depositApr: depositInterest / deposits,
        rewardApr: (rewardDepositAccrual + rewardBorrowAccrual) / nav,
        rewardDepositApr: rewardDepositAccrual / deposits,
        rewardBorrowApr: rewardBorrowAccrual / debt,
        stakingApr: (stakingDepositAccrual - stakingBorrowAccrual) / nav,
        stakingDepositApr: stakingDepositAccrual / deposits,
        stakingBorrowApr: stakingBorrowAccrual / debt,
      }

    })
    .addCase(fetchMeridianUserData.pending, (state) => {
      //
    })
    // ALLOWANCES
    .addCase(fetchMeridianAllowances.fulfilled, (state, action) => {
      const assetKeys = Object.keys(action.payload.allowances)
      const { chainId, account } = action.payload
      if (!state[chainId].userData) {
        state[chainId].userData = { [account]: {} }
      }
      if (state[chainId].userData[account])
        for (let i = 0; i < assetKeys.length; i++) {
          const asset = assetKeys[i]
          state[chainId].userData[account][asset] = {
            ...(state[chainId].userData[account]?.[asset] ?? {}),
            ...action.payload.allowances[asset]
          }
        }
    })
    .addCase(fetchMeridianAllowances.pending, (state) => {
      //
    })
)