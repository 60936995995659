import { getBalacneViemProvider, getFallbackViemProvider, getViemProvider } from 'hooks/providers/evm/useViemClient'
import { Address } from 'viem'

export type MultiCallResponse<T> = T | null

export interface Call {
  address: string // Address of the contract
  name: string // Function name on the contract (example: balanceOf)
  params?: any[] // Function params
}

interface ViemMulticallresult {
  result: any[],
  status: string
}

export enum ProviderType {
  default = 0,
  fallback = 1,
  balance = 2
}

export const multicallViem = async (chainId: number, abi: any[], calls: Call[], providerId = 0, retry = true, providerType = ProviderType.default, requireSuccess = false): Promise<any[]> => {
  try {
    const provider = getProvider(chainId, providerId, providerType)

    const returnData = await provider.multicall({
      allowFailure: !requireSuccess,
      contracts: calls.map(({ address, name, params }) => ({
        abi: abi as any,
        address: address as Address,
        functionName: name,
        args: params,
      })),
    })
    const res = (returnData as any[]).map(
      ({ result, status }: ViemMulticallresult) => status !== 'success' ? '0x' : result
    )

    return res
  } catch (error: any) {
    // console.log("Error with multicall", error)
    if ((providerType === ProviderType.fallback) || !retry) // if we are already in the fallback, we throw an error
      throw new Error(error)
    else
      return multicallViem(chainId, abi, calls, providerId, false, ProviderType.fallback, requireSuccess)
  }
}

export function fallbackOnResult(result: string, fallbackValue = '0') {
  return result === '0x' ? fallbackValue : result
}

function getProvider(chainId: number, providerId: number, providerType = ProviderType.default) {
  switch (providerType) {
    case ProviderType.default: return getViemProvider({ chainId, id: providerId })
    case ProviderType.fallback: return getFallbackViemProvider({ chainId, id: providerId })
    case ProviderType.balance: return getBalacneViemProvider({ chainId, id: providerId })
  }
}