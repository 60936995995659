import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { Call, multicallViem } from 'utils/multicall'
import STABLE_DEBT_TOKEN from 'abis/aave/StableDebtToken.json'
import { getAddressesForChainIdFromAssetDict } from 'hooks/1delta/addresses'
import { ONE_DELTA_COMPOSER } from 'hooks/1delta/addresses1Delta'
import { SerializedBigNumber } from 'types/1delta'
import { Lender } from 'types/lenderData/base'
import { getLenderTokenAddresses } from 'hooks/lenders/lenderAddressGetter'
import { addressesMeridianATokens, addressesMeridianSTokens, addressesMeridianVTokens } from 'hooks/1delta/addressesMeridian'
import { addressesMeridianCore } from 'hooks/1delta/addressesMeridian'

const fallbackDebtToken = '0x52A1CeB68Ee6b7B5D13E0376A1E0E4423A8cE26e'

interface AllowanceData {
  allowanceDepositDirect: SerializedBigNumber
  allowanceWithdrawal: SerializedBigNumber
  allowanceBorrowingVariable: SerializedBigNumber
  allowanceBorrowingStable: SerializedBigNumber
}

interface AllowanceResponse {
  allowances: { [asset: string]: AllowanceData }
  chainId: number
  account: string
}

interface AllowanceQuery {
  chainId: number
  account: string | undefined
}

export const fetchMeridianAllowances: AsyncThunk<
  AllowanceResponse,
  AllowanceQuery,
  any
> = createAsyncThunk<AllowanceResponse, AllowanceQuery>(
  'meridian/fetchMeridianAllowances',

  async ({ chainId, account }) => {
    const broker = ONE_DELTA_COMPOSER[chainId]
    const aavePool = addressesMeridianCore.PoolProxy[chainId]

    if (!account || !aavePool || !broker) return {
      allowances: {},
      chainId,
      account: ''
    }

    const tokenAddresses = getLenderTokenAddresses(chainId, Lender.MERIDIAN)

    const aTokenDict = getAddressesForChainIdFromAssetDict(
      addressesMeridianATokens,
      chainId
    )

    const tokenNames = Object.keys(aTokenDict)

    const vTokenDict = getAddressesForChainIdFromAssetDict(
      addressesMeridianVTokens,
      chainId
    )

    const sTokenDict = getAddressesForChainIdFromAssetDict(
      addressesMeridianSTokens,
      chainId
    )

    const callsDirect: Call[] = tokenNames.map((tk) => {
      return {
        address: tokenAddresses[tk],
        name: 'allowance',
        params: [account, aavePool],
      }
    })
    const callsWithdraw: Call[] = tokenNames.map((tk) => {
      return {
        address: aTokenDict[tk],
        name: 'allowance',
        params: [account, broker],
      }
    })
    const callsBorrowVariable: Call[] = tokenNames.map((tk) => {
      return {
        address: vTokenDict[tk] ?? fallbackDebtToken,
        name: 'borrowAllowance',
        params: [account, broker],
      }
    })
    const callsBorrowStable: Call[] = tokenNames.map((tk) => {
      return {
        address: sTokenDict[tk] ?? fallbackDebtToken,
        name: 'borrowAllowance',
        params: [account, broker],
      }
    })

    try {
      const multicallResult: any[] = await multicallViem(
        chainId,
        STABLE_DEBT_TOKEN,
        [...callsDirect, ...callsWithdraw, ...callsBorrowVariable, ...callsBorrowStable],
      )

      const directResults = multicallResult.slice(0, callsDirect.length)
      const collateralResults = multicallResult.slice(callsDirect.length, callsDirect.length + callsWithdraw.length)
      const delegationVariableResults = multicallResult.slice(
        callsDirect.length + callsWithdraw.length,
        callsDirect.length + callsWithdraw.length + callsBorrowVariable.length
      )
      const delegationStableResults = multicallResult.slice(
        callsDirect.length + callsWithdraw.length + callsBorrowVariable.length,
        multicallResult.length
      )

      const result: any = Object.assign(
        {},
        ...tokenNames.map((name, index) => {
          return {
            [name]: {
              allowanceDepositDirect: directResults[index]?.toString(),
              allowanceWithdrawal: collateralResults[index]?.toString(),
              allowanceBorrowingVariable: delegationVariableResults[index]?.toString(),
              allowanceBorrowingStable: delegationStableResults[index]?.toString(),
            }
          }
        })
      )
      return {
        allowances: result,
        chainId,
        account
      }
    } catch (error) {
      console.log(error)
      return {
        allowances: {},
        chainId,
        account: ''
      }
    }
  }
)