import { Lender } from 'types/lenderData/base'
import { Call } from 'utils/multicall'
import { getInitLensContract } from 'hooks/1delta/use1DeltaContract'
import { initBrokerAddresses } from 'hooks/1delta/addresses1Delta'

export const buildInitUserCall = (chainId: number, lender: Lender, account: string): Call[] => {
  switch (lender) {
    case Lender.INIT: {
      const lensContract = getInitLensContract(chainId)
      const brokerAddress = initBrokerAddresses[chainId] ?? ''

      const call: Call = {
        address: lensContract.address,
        name: 'getUserPositionData',
        params: [account, brokerAddress],
      }

      return [call]
    }
    default:
      return []
  }
}
