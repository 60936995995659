import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { multicallViem } from 'utils/multicall'
import STABLE_DEBT_TOKEN from 'abis/aave/StableDebtToken.json'
import { getAddressesForChainIdFromAssetDict } from 'hooks/1delta/addresses'
import { ONE_DELTA_COMPOSER } from 'hooks/1delta/addresses1Delta'
import { SerializedBigNumber } from 'types/1delta'
import { Lender } from 'types/lenderData/base'
import { getLenderTokenAddresses } from 'hooks/lenders/lenderAddressGetter'
import { addressesAureliusATokens, addressesAureliusCore, addressesAureliusSTokens, addressesAureliusVTokens } from 'hooks/1delta/addressesAurelius'
import { getAaveStyleATokenMap } from 'hooks/1delta/tokens'

const fallbackDebtToken = '0x52A1CeB68Ee6b7B5D13E0376A1E0E4423A8cE26e'

interface AllowanceData {
  allowanceDepositDirect: SerializedBigNumber
  allowanceWithdrawal: SerializedBigNumber
  allowanceBorrowingVariable: SerializedBigNumber
  allowanceBorrowingStable: SerializedBigNumber
}

interface AllowanceResponse {
  allowances: { [asset: string]: AllowanceData }
  chainId: number
  account: string
}

interface AllowanceQuery {
  chainId: number
  account: string | undefined
}

const getAbi = (fork: string) => {
  switch (fork) {
    case Lender.AURELIUS:
      return [...STABLE_DEBT_TOKEN]
    default:
      return []
  }
}

const buildLenderCall = (chainId: number, lender: Lender, account: string) => {
  switch (lender) {
    case Lender.AURELIUS:
    case Lender.AAVE_V2:
    case Lender.AAVE_V3:
    case Lender.LENDLE:
    case Lender.MERIDIAN: {
      const broker = ONE_DELTA_COMPOSER[chainId]
      const aureliusPool = addressesAureliusCore.PoolProxy[chainId]
      const tokenAddresses = getLenderTokenAddresses(chainId, lender)

      const aTokenDict = getAddressesForChainIdFromAssetDict(
        addressesAureliusATokens,
        chainId
      )

      const tokenNames = Object.keys(aTokenDict)

      const vTokenDict = getAddressesForChainIdFromAssetDict(
        addressesAureliusVTokens,
        chainId
      )

      const sTokenDict = getAddressesForChainIdFromAssetDict(
        addressesAureliusSTokens,
        chainId
      )

      return [
        ...tokenNames.flatMap((tk) => [
          {
            address: tokenAddresses[tk],
            name: 'allowance',
            params: [account, aureliusPool],
          },
          {
            address: aTokenDict[tk],
            name: 'allowance',
            params: [account, broker],
          },
          {
            address: vTokenDict[tk] ?? fallbackDebtToken,
            name: 'borrowAllowance',
            params: [account, broker],
          },
          {
            address: sTokenDict[tk] ?? fallbackDebtToken,
            name: 'borrowAllowance',
            params: [account, broker],
          }
        ]),
      ]
    }
    default:
      return []
  }
}

const fetchAndConvertDataSlice = (
  lender: Lender,
  chainId: number,
  account: string
): [(data: any[]) => AllowanceResponse | undefined, number] => {
  switch (lender) {
    case Lender.AURELIUS:
    case Lender.AAVE_V2:
    case Lender.AAVE_V3:
    case Lender.LENDLE:
    case Lender.MERIDIAN: {
      const aTokenDict = getAaveStyleATokenMap(chainId, lender)
      const tokenNames = Object.keys(aTokenDict)
      const expectedNumberOfCalls = tokenNames.length * 4

      return [
        (data: any[]) => {
          if (data.length != expectedNumberOfCalls) {
            return undefined
          }

          const allowances = Object.fromEntries(
            tokenNames.map((name, index) => {
              const baseIndex = index * 4
              return [
                name,
                {
                  allowanceDepositDirect: data[baseIndex]?.toString(),
                  allowanceWithdrawal: data[baseIndex + 1]?.toString(),
                  allowanceBorrowingVariable: data[baseIndex + 2]?.toString(),
                  allowanceBorrowingStable: data[baseIndex + 3]?.toString(),
                }
              ]
            })
          )

          return {
            allowances,
            chainId,
            account
          }
        },
        expectedNumberOfCalls,
      ]
    }
    default: {
      return [() => undefined, 0]
    }
  }
}

const getLenderData = async (
  chainId: number,
  protocols: Lender[],
  account: string,
): Promise<{ [fork: string]: AllowanceResponse }> => {
  let calls: {
    call: {
      address: string
      name: string
      params: any[] | undefined
    }
    abi: any
  }[] = []

  for (const fork of protocols) {
    const abi = getAbi(fork)
    const callData = buildLenderCall(chainId, fork, account)
    const mappedCalls = callData.map((call) => ({ call, abi }))
    calls = [...calls, ...mappedCalls]
  }

  const rawResults = await multicallViem(
    chainId,
    calls.flatMap((call) => call.abi),
    calls.map((call) => call.call),
  )

  const invalidForks: string[] = []
  const lenderData: { [lender: string]: AllowanceResponse } = {}

  let currentSlice = 0
  for (const fork of protocols) {
    const [converter, sliceLength] = fetchAndConvertDataSlice(fork, chainId, account)

    const data = rawResults.slice(currentSlice, currentSlice + sliceLength)
    const convertedData = converter(data)
    if (!convertedData) {
      invalidForks.push(fork)
    } else {
      lenderData[fork] = convertedData
    }

    currentSlice += sliceLength
  }

  return lenderData
}

export const fetchAureliusAllowances: AsyncThunk<
  AllowanceResponse,
  AllowanceQuery,
  any
> = createAsyncThunk<AllowanceResponse, AllowanceQuery>(
  'aurelius/fetchAureliusAllowances',

  async ({ chainId, account }) => {
    const broker = ONE_DELTA_COMPOSER[chainId]
    const aureliusPool = addressesAureliusCore.PoolProxy[chainId]

    if (!account || !aureliusPool || !broker) return {
      allowances: {},
      chainId,
      account: ''
    }

    try {
      const lenderData = await getLenderData(chainId, [Lender.AURELIUS], account)
      return lenderData[Lender.AURELIUS]
    } catch (error) {
      console.error('Error fetching Aurelius allowances:', error)
      return {
        allowances: {},
        chainId,
        account: ''
      }
    }
  }
)