import { Currency, Token } from '@1delta/base-sdk'
import { isSupportedChain } from 'constants/chains'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { useMemo } from 'react'

import { TOKEN_SHORTHANDS } from '../../constants/tokens'
import { isAddress } from '../../utils'
import { supportedChainId } from '../../utils/supportedChainId'
import { useChainId } from 'state/globalNetwork/hooks'
import { useTokensFromChain } from 'state/lists/hooks'
import { ChainIdMap } from 'utils/Types'

type TokenMap = { [address: string]: Token }

/**
 * Returns a Currency from the currencyId.
 * Returns null if currency is loading or null was passed.
 * Returns undefined if currencyId is invalid or token does not exist.
 */
export function useCurrencyFromMap(tokens: TokenMap, currencyId?: string | null): Currency | undefined {
  const nativeCurrency = useNativeCurrency()
  const chainId = useChainId()
  const isNative = Boolean(nativeCurrency &&
    (currencyId?.toUpperCase() === 'ETH' || currencyId?.toUpperCase() === 'MNT' || currencyId?.toUpperCase() === 'MATIC' || currencyId?.toUpperCase() === 'BNB' || currencyId?.toUpperCase() === 'AVAX'))
  const shorthandMatchAddress = useMemo(() => {
    const chain = supportedChainId(chainId)
    return chain && currencyId ? TOKEN_SHORTHANDS[currencyId.toUpperCase()]?.[chain] : undefined
  }, [chainId, currencyId])
  const token = useTokenFromMapOrNetworkAcrossChains([chainId], { [chainId]: tokens }, isNative ? undefined : shorthandMatchAddress ?? currencyId)?.[chainId]
  const supportedChain = isSupportedChain(chainId)
  if (currencyId === null || currencyId === undefined || !supportedChain) return undefined

  // this case so we use our builtin wrapped token instead of wrapped tokens on token lists
  const wrappedNative = nativeCurrency?.wrapped
  if (wrappedNative?.address?.toUpperCase() === currencyId?.toUpperCase()) return wrappedNative

  return isNative ? nativeCurrency : token
}

type CrossChainTokenMap = { [chainId: number]: TokenMap }
/**
 * Returns a Token from the tokenAddress.
 * Returns null if token is loading or null was passed.
 * Returns undefined if tokenAddress is invalid or token does not exist.
 */
export function useTokenFromMapOrNetworkAcrossChains(chainIds: number[], tokens: CrossChainTokenMap, tokenAddress?: string | null): { [chainId: number]: Token | undefined } {
  const address = isAddress(tokenAddress)
  const tokenFromList = useMemo(() => tryGetTokenFromXChainMap(chainIds, tokens, address), [chainIds, tokens, address])
  const tokenInList = Object.values(tokenFromList).length > 0
  // address defined: 
  //  token in list:
  //    return map
  //  not in list:
  //    return undefined
  // not defined:
  // -> return undefined
  const token: ChainIdMap<Token> | undefined = address ? (tokenInList ? tokenFromList : undefined) : undefined

  const tokenFromNetwork = useTokensFromChain(chainIds, token ? undefined : address ? address : undefined)
  return tokenInList ? token! : tokenFromNetwork
}


function tryGetTokenFromXChainMap(chainIds: number[], tokens: CrossChainTokenMap, tokenAddress?: string | false) {
  let tokensFound: ChainIdMap<Token> = {}
  if (!tokenAddress) return tokensFound
  const tokenKey = tokenAddress?.toLowerCase()
  chainIds.map(cId => {
    if (tokens[cId]?.[tokenKey]) {
      tokensFound[cId] = tokens[cId][tokenKey]
    }
  })
  return tokensFound
}