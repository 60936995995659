import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { Lender } from 'types/lenderData/base'
import { ChainIdMap } from 'utils/Types'
import { getLenderUserData } from '../fetchers/fetchUserData'
import { AaveV2UserReserveResponse } from '../fetchers/aave-v2-type/types'

interface MeridianUserReservesQueryParams {
  chainId: number
  account: string
}

export const fetchMeridianUserData: AsyncThunk<
  AaveV2UserReserveResponse,
  MeridianUserReservesQueryParams,
  any
> = createAsyncThunk<
  AaveV2UserReserveResponse,
  MeridianUserReservesQueryParams,
  {
    state: {
      oracles: {
        live: ChainIdMap<number>
      }
    },
  }>(
    'meridian/fetchMeridanUserData',
    async ({ chainId, account }, { getState }) => {
      if (!account) {
        return { chainId, tokensData: {}, rewards: {}, allowances: {}, account: '' }
      }

      // get fields from other slices
      const prices = getState().oracles.live
      try {
        const lenderData = await getLenderUserData(chainId, [Lender.MERIDIAN], account, prices)
        return lenderData[Lender.MERIDIAN]
      } catch (error) {
        console.error('Error fetching meridian user data:', error)
        return {
          data: {},
          config: {},
          chainId,
        }
      }
    }
  )
