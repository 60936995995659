import { BPS, TOKEN_META } from "constants/1delta";
import { getLenderAssets } from "constants/getAssets";
import { AdditionalYields } from "state/oracles/reducer";
import { SupportedAssets, toOracleKey } from "types/1delta";
import { Lender, LENDER_MODE_NO_MODE } from "types/lenderData/base";
import { formatAaveRawApyToApr } from "utils/1delta/generalFormatters";
import { convertRateToApr, parseRawAmount } from "utils/tableUtils/prices";
import {
  AaveV2GeneralPublicResponse,
  AaveV2TypeGetReserveConfigurationData,
  AaveV2TypeGetReserveDataIndexes,
  AaveV2TypeIncentivesControllerPoolInfoIndexes
} from "./types";

export const getAaveV2ReservesDataConverter = (
  lender: Lender,
  chainId: number,
  prices: { [asset: string]: number },
  additionalYields: AdditionalYields
): [(data: any[]) => AaveV2GeneralPublicResponse | undefined, number] => {
  switch (lender) {
    case Lender.AURELIUS: {
      const assetsToQuery = getLenderAssets(chainId, lender)

      const expectedNumberOfCalls = assetsToQuery.length * 6
      return [
        (data: any[]) => {
          if (data.length !== expectedNumberOfCalls) {
            return undefined
          }

          const resultReserves: any = {}
          const resultConfig: any = {}

          for (let i = 0; i < assetsToQuery.length; i++) {
            const asset = assetsToQuery[i]
            const reserveData = data[i * 6]
            const configData = data[i * 6 + 1]

            const [, emission0PerSecondCollateral, ,] = data[i * 6 + 2]
            const [, emission1PerSecondCollateral, ,] = data[i * 6 + 3]
            const [, emission0PerSecondDebt, ,] = data[i * 6 + 4]
            const [, emission1PerSecondDebt, ,] = data[i * 6 + 5]

            const decimals = TOKEN_META[asset]?.decimals ?? 18
            const totalStableDebt = parseRawAmount(reserveData?.[AaveV2TypeGetReserveDataIndexes.totalStableDebt]?.toString(), decimals)
            const totalVariableDebt = parseRawAmount(reserveData?.[AaveV2TypeGetReserveDataIndexes.totalVariableDebt]?.toString(), decimals)
            const liquidity = parseRawAmount(reserveData?.[AaveV2TypeGetReserveDataIndexes.availableLiquidity]?.toString(), decimals)
            const totalAToken = liquidity + totalStableDebt + totalVariableDebt
            const price = prices[toOracleKey(asset)] ?? 1

            const totalDepositsUSD = totalAToken * price
            const totalDebtUSD = totalVariableDebt * price

            resultReserves[asset] = {
              // token amounts
              totalDeposits: totalAToken,
              totalDebtStable: totalStableDebt,
              totalDebt: totalVariableDebt,
              totalLiquidity: liquidity,
              // USD amounts
              totalDepositsUSD,
              totalDebtStableUSD: totalStableDebt * price,
              totalDebtUSD,
              totalLiquidityUSD: liquidity * price,
              // rates
              depositRate: formatAaveRawApyToApr(reserveData?.[AaveV2TypeGetReserveDataIndexes.liquidityRate]?.toString()),
              variableBorrowRate: formatAaveRawApyToApr(reserveData?.[AaveV2TypeGetReserveDataIndexes.variableBorrowRate]?.toString()),
              stableBorrowRate: formatAaveRawApyToApr(reserveData?.[AaveV2TypeGetReserveDataIndexes.stableBorrowRate]?.toString()),
              stakingYield: additionalYields.intrinsicYields[asset] ?? 0,

              // rewards
              rewards: {
                [SupportedAssets.WMNT]: {
                  depositRate: convertRateToApr(parseRawAmount(emission0PerSecondCollateral)) / totalDepositsUSD * prices[SupportedAssets.WMNT],
                  variableBorrowRate: convertRateToApr(parseRawAmount(emission0PerSecondDebt)) / totalDebtUSD * prices[SupportedAssets.WMNT],
                  stableBorrowRate: 0,
                },
                // oAU allows for a 50% discount to pruchase AU, hence the price is the price of AU divided by 2
                [SupportedAssets.OAU]: {
                  depositRate: convertRateToApr(parseRawAmount(emission1PerSecondCollateral)) / totalDepositsUSD * prices['AU'] / 2,
                  variableBorrowRate: convertRateToApr(parseRawAmount(emission1PerSecondDebt)) / totalDebtUSD * prices['AU'] / 2,
                  stableBorrowRate: 0,
                },
              }
            }

            resultConfig[asset] = {
              decimals: Number(configData?.[AaveV2TypeGetReserveConfigurationData.decimals]),
              config: {
                [LENDER_MODE_NO_MODE]: {
                  modeId: LENDER_MODE_NO_MODE,
                  // collateral factors
                  borrowCollateralFactor: Number(configData?.[AaveV2TypeGetReserveConfigurationData.ltv].toString()) / BPS,
                  collateralFactor: Number(configData?.[AaveV2TypeGetReserveConfigurationData.liquidationThreshold].toString()) / BPS,
                  borrowFactor: 1
                }
              },
              liquidationBonus: Number(configData?.[AaveV2TypeGetReserveConfigurationData.liquidationBonus].toString()) / BPS,
              // flags
              collateralActive: configData?.[AaveV2TypeGetReserveConfigurationData.usageAsCollateralEnabled],
              borrowingEnabled: configData?.[AaveV2TypeGetReserveConfigurationData.borrowingEnabled],
              hasStable: configData?.[AaveV2TypeGetReserveConfigurationData.stableBorrowRateEnabled],
              isActive: configData?.[AaveV2TypeGetReserveConfigurationData.isActive],
              isFrozen: configData?.[AaveV2TypeGetReserveConfigurationData.isFrozen],
            }
          }

          return {
            data: resultReserves,
            config: resultConfig,
            chainId,
          }
        },
        expectedNumberOfCalls,
      ]
    }
    case Lender.LENDLE: {
      const assetsToQuery = getLenderAssets(chainId, lender)
      const expectedNumberOfCalls = assetsToQuery.length * 4 + 2

      return [
        (data: any[]) => {
          if (data.length !== expectedNumberOfCalls) {
            return undefined
          }

          const rewardsPerSecond = parseRawAmount(data[expectedNumberOfCalls - 2].toString(), 18)
          const totalAllocPoint = Number(data[expectedNumberOfCalls - 1].toString())

          const resultReserves: any = {}
          const resultConfig: any = {}

          const lendPrice = prices[SupportedAssets.LEND]

          for (let i = 0; i < assetsToQuery.length; i++) {
            const asset = assetsToQuery[i]
            const reserveData = data[i * 4]
            const configData = data[i * 4 + 1]

            const collateralRewardIndex = i * 4 + 2
            const borrowRewardIndex = i * 4 + 3
            const collateralRewards = Number(data?.[collateralRewardIndex]?.[AaveV2TypeIncentivesControllerPoolInfoIndexes.allocPoint].toString()) / totalAllocPoint * rewardsPerSecond
            const borrowRewards = Number(data?.[borrowRewardIndex]?.[AaveV2TypeIncentivesControllerPoolInfoIndexes.allocPoint].toString()) / totalAllocPoint * rewardsPerSecond

            const decimals = TOKEN_META[asset]?.decimals ?? 18

            const totalStableDebt = parseRawAmount(reserveData?.[AaveV2TypeGetReserveDataIndexes.totalStableDebt]?.toString(), decimals)
            const totalVariableDebt = parseRawAmount(reserveData?.[AaveV2TypeGetReserveDataIndexes.totalVariableDebt]?.toString(), decimals)
            const liquidity = parseRawAmount(reserveData?.[AaveV2TypeGetReserveDataIndexes.availableLiquidity]?.toString(), decimals)
            const totalAToken = liquidity + totalStableDebt + totalVariableDebt
            const price = prices[toOracleKey(asset)] ?? 1

            const totalDepositsUSD = totalAToken * price
            const totalDebtUSD = totalVariableDebt * price



            resultReserves[asset] = {
              // token amounts
              totalDeposits: totalAToken,
              totalDebtStable: totalStableDebt,
              totalDebt: totalVariableDebt,
              totalLiquidity: liquidity,
              // USD amounts
              totalDepositsUSD,
              totalDebtStableUSD: totalStableDebt * price,
              totalDebtUSD,
              totalLiquidityUSD: liquidity * price,
              // rates
              depositRate: formatAaveRawApyToApr(reserveData?.[AaveV2TypeGetReserveDataIndexes.liquidityRate]?.toString()),
              variableBorrowRate: formatAaveRawApyToApr(reserveData?.[AaveV2TypeGetReserveDataIndexes.variableBorrowRate]?.toString()),
              stableBorrowRate: formatAaveRawApyToApr(reserveData?.[AaveV2TypeGetReserveDataIndexes.stableBorrowRate]?.toString()),
              stakingYield: additionalYields.intrinsicYields[asset] ?? 0,

              // rewards
              rewards: {
                [SupportedAssets.LEND]: {
                  depositRate: convertRateToApr(collateralRewards / totalDepositsUSD) * lendPrice,
                  variableBorrowRate: convertRateToApr(totalDebtUSD > 0 ? borrowRewards / totalDebtUSD : 0) * lendPrice,
                  stableBorrowRate: 0,
                },
              }
            }

            resultConfig[asset] = {
              decimals: Number(configData?.[AaveV2TypeGetReserveConfigurationData.decimals]),
              config: {
                [LENDER_MODE_NO_MODE]: {
                  modeId: LENDER_MODE_NO_MODE,
                  // collateral factors
                  borrowCollateralFactor: Number(configData?.[AaveV2TypeGetReserveConfigurationData.ltv].toString()) / BPS,
                  collateralFactor: Number(configData?.[AaveV2TypeGetReserveConfigurationData.liquidationThreshold].toString()) / BPS,
                  borrowFactor: 1
                }
              },
              liquidationBonus: Number(configData?.[AaveV2TypeGetReserveConfigurationData.liquidationBonus].toString()) / BPS,
              // flags
              collateralActive: configData?.[AaveV2TypeGetReserveConfigurationData.usageAsCollateralEnabled],
              borrowingEnabled: configData?.[AaveV2TypeGetReserveConfigurationData.borrowingEnabled],
              hasStable: configData?.[AaveV2TypeGetReserveConfigurationData.stableBorrowRateEnabled],
              isActive: configData?.[AaveV2TypeGetReserveConfigurationData.isActive],
              isFrozen: configData?.[AaveV2TypeGetReserveConfigurationData.isFrozen],
            }
          }

          return {
            data: resultReserves,
            config: resultConfig,
            chainId,
          }
        },
        expectedNumberOfCalls,
      ]
    }
    /** AAVE V2 style with rewards from state */
    default: {
      const assetsToQuery = getLenderAssets(chainId, lender)

      const expectedNumberOfCalls = assetsToQuery.length * 2

      return [
        (data: any[]) => {
          if (data.length !== expectedNumberOfCalls) {
            return undefined
          }

          const resultReserves: any = {}
          const resultConfig: any = {}

          for (let i = 0; i < assetsToQuery.length; i++) {
            const asset = assetsToQuery[i]
            const reserveData = data[i * 2]
            const configData = data[i * 2 + 1]

            const decimals = TOKEN_META[asset]?.decimals ?? 18
            const totalStableDebt = parseRawAmount(reserveData?.[AaveV2TypeGetReserveDataIndexes.totalStableDebt]?.toString(), decimals)
            const totalVariableDebt = parseRawAmount(reserveData?.[AaveV2TypeGetReserveDataIndexes.totalVariableDebt]?.toString(), decimals)
            const liquidity = parseRawAmount(reserveData?.[AaveV2TypeGetReserveDataIndexes.availableLiquidity]?.toString(), decimals)
            const totalAToken = liquidity + totalStableDebt + totalVariableDebt
            const price = prices[toOracleKey(asset)] ?? 1

            const totalDepositsUSD = totalAToken * price
            const totalDebtUSD = totalVariableDebt * price

            resultReserves[asset] = {
              // token amounts
              totalDeposits: totalAToken,
              totalDebtStable: totalStableDebt,
              totalDebt: totalVariableDebt,
              totalLiquidity: liquidity,
              // USD amounts
              totalDepositsUSD,
              totalDebtStableUSD: totalStableDebt * price,
              totalDebtUSD,
              totalLiquidityUSD: liquidity * price,
              // rates
              depositRate: formatAaveRawApyToApr(reserveData?.[AaveV2TypeGetReserveDataIndexes.liquidityRate]?.toString()),
              variableBorrowRate: formatAaveRawApyToApr(reserveData?.[AaveV2TypeGetReserveDataIndexes.variableBorrowRate]?.toString()),
              stableBorrowRate: formatAaveRawApyToApr(reserveData?.[AaveV2TypeGetReserveDataIndexes.stableBorrowRate]?.toString()),
              stakingYield: additionalYields.intrinsicYields[asset] ?? 0,

              // rewards
              rewards: lender === Lender.MERIDIAN ? {
                [SupportedAssets.TAIKO]: {
                  depositRate: additionalYields.lenderRewards[Lender.MERIDIAN]?.[asset]?.deposit ?? 0,
                  variableBorrowRate: additionalYields.lenderRewards[Lender.MERIDIAN]?.[asset]?.borrow ?? 0,
                  stableBorrowRate: 0
                }
              } : {}
            }

            resultConfig[asset] = {
              decimals: Number(configData?.[AaveV2TypeGetReserveConfigurationData.decimals]),
              config: {
                [LENDER_MODE_NO_MODE]: {
                  modeId: LENDER_MODE_NO_MODE,
                  // collateral factors
                  borrowCollateralFactor: Number(configData?.[AaveV2TypeGetReserveConfigurationData.ltv].toString()) / BPS,
                  collateralFactor: Number(configData?.[AaveV2TypeGetReserveConfigurationData.liquidationThreshold].toString()) / BPS,
                  borrowFactor: 1
                }
              },
              liquidationBonus: Number(configData?.[AaveV2TypeGetReserveConfigurationData.liquidationBonus].toString()) / BPS,
              // flags
              collateralActive: configData?.[AaveV2TypeGetReserveConfigurationData.usageAsCollateralEnabled],
              borrowingEnabled: configData?.[AaveV2TypeGetReserveConfigurationData.borrowingEnabled],
              hasStable: configData?.[AaveV2TypeGetReserveConfigurationData.stableBorrowRateEnabled],
              isActive: configData?.[AaveV2TypeGetReserveConfigurationData.isActive],
              isFrozen: configData?.[AaveV2TypeGetReserveConfigurationData.isFrozen],
            }
          }

          return {
            data: resultReserves,
            config: resultConfig,
            chainId,
          }
        },
        expectedNumberOfCalls,
      ]
    }
  }
}