import { SupportedChainId } from './chains'

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY
if (typeof INFURA_KEY === 'undefined') {
  console.warn(`REACT_APP_INFURA_KEY must be a defined environment variable`)
}

const QUICKNODE_RPC_URL = process.env.REACT_APP_QUICKNODE_RPC_URL
if (typeof QUICKNODE_RPC_URL === 'undefined') {
  console.warn(`REACT_APP_QUICKNODE_RPC_URL must be a defined environment variable`)
}


/**
 * These are the URLs used by the interface when there is not another available source of chain data.
 * MetaMask allows switching to any URL, but displays a warning if it is not on the "Safe" list:
 * https://github.com/MetaMask/metamask-mobile/blob/bdb7f37c90e4fc923881a07fca38d4e77c73a579/app/core/RPCMethods/wallet_addEthereumChain.js#L228-L235
 * https://chainid.network/chains.json
 *
 * These "Safe" URLs are listed first, followed by other fallback URLs, which are taken from chainlist.org.
 */
export const RPC_URLS: { [chainId: number]: string[] } = {
  [SupportedChainId.MAINNET]: [
    'https://rpc.ankr.com/eth',
  ],
  [SupportedChainId.GOERLI]: [
    'https://rpc.ankr.com/eth_goerli',
  ],
  [SupportedChainId.BSC]: [
    "https://bsc-dataseed.bnbchain.org",
    'https://binance.nodereal.io',
    'https://bsc-dataseed.binance.org',
    'https://bsc-dataseed1.binance.org'
  ],
  [SupportedChainId.POLYGON]: [
    'https://rpc.ankr.com/polygon',
    'https://polygon.meowrpc.com',
    // 'https://polygon-mainnet.public.blastapi.io',
    'https://polygon-bor.publicnode.com',
  ],
  [SupportedChainId.POLYGON_MUMBAI]: [
    'https://polygon-testnet.public.blastapi.io',
    'https://rpc.ankr.com/polygon_mumbai',
    'https://polygon-mumbai-bor.publicnode.com',
  ],
  [SupportedChainId.MANTLE]: [
    'https://rpc.mantle.xyz/',
    // 'https://mantle.drpc.org',
    'https://mantle-mainnet.public.blastapi.io',
  ],
  [SupportedChainId.ARBITRUM_ONE]: [
    'https://arb1.arbitrum.io/rpc',
    // 'https://arbitrum.public-rpc.com',
  ],
  [SupportedChainId.BASE]: [
    'https://base.llamarpc.com',
    'https://base-rpc.publicnode.com',
  ],
  [SupportedChainId.LINEA]: [
    'https://rpc.linea.build',
    'https://1rpc.io/linea',
  ],
  [SupportedChainId.AVALANCHE]: [
    'https://avalanche-c-chain-rpc.publicnode.com',
    'https://avalanche.drpc.org',
    'https://1rpc.io/avax/c',
  ],
  /** UNUSED */
  [SupportedChainId.ARBITRUM_RINKEBY]: [
    'https://rinkeby.arbitrum.io/rpc',
  ],
  [SupportedChainId.OPTIMISM]: [
    'https://optimism.llamarpc.com',
    'https://optimism-rpc.publicnode.com',
  ],
  [SupportedChainId.OPTIMISM_GOERLI]: [
    'https://goerli.optimism.io',
  ],
  [SupportedChainId.CELO]: [
    `https://forno.celo.org`,
  ],
  [SupportedChainId.CELO_ALFAJORES]: [
    `https://alfajores-forno.celo-testnet.org`,
  ],
  [SupportedChainId.BLAST]: [
    `https://rpc.blast.io`,
    `https://rpc.envelop.is/blast`,
  ],
  [SupportedChainId.TAIKO]: [
    `https://rpc.taiko.xyz`,
    `https://rpc.ankr.com/taiko`,
  ],
}

/**
 * Fallback JSON-RPC endpoints.
 */
export const FALLBACK_URLS: { [chainId: number]: string[] } = {
  [SupportedChainId.MAINNET]: [
    'https://eth-mainnet.public.blastapi.io',
  ],
  [SupportedChainId.GOERLI]: [
    'https://rpc.ankr.com/eth_goerli',
  ],
  [SupportedChainId.BSC]: [
    "https://bsc-dataseed.bnbchain.org",
    'https://binance.nodereal.io',
    'https://bsc-dataseed.binance.org',
    'https://bsc-dataseed1.binance.org'
  ],
  [SupportedChainId.POLYGON]: [
    'https://polygon.meowrpc.com',
    'https://polygon-rpc.com'
  ],
  [SupportedChainId.POLYGON_MUMBAI]: [
    'https://polygon-testnet.public.blastapi.io',
    'https://rpc.ankr.com/polygon_mumbai',
    'https://polygon-mumbai-bor.publicnode.com',

  ],
  [SupportedChainId.MANTLE]: [
    'https://1rpc.io/mantle',
    'https://rpc.ankr.com/mantle',
  ],
  /** UNUSED */
  [SupportedChainId.ARBITRUM_ONE]: [
    'https://arb1.arbitrum.io/rpc',
    // 'https://arbitrum.public-rpc.com',
  ],
  [SupportedChainId.ARBITRUM_RINKEBY]: [
    'https://rinkeby.arbitrum.io/rpc',
  ],
  [SupportedChainId.BASE]: [
    'https://base-pokt.nodies.app',
    'https://base.drpc.org',
  ],
  [SupportedChainId.OPTIMISM]: [
    'https://optimism.llamarpc.com',
    'https://optimism-rpc.publicnode.com',
  ],
  [SupportedChainId.OPTIMISM_GOERLI]: [
    'https://goerli.optimism.io',
  ],
  [SupportedChainId.CELO]: [
    `https://forno.celo.org`,
  ],
  [SupportedChainId.CELO_ALFAJORES]: [
    `https://alfajores-forno.celo-testnet.org`,
  ],
  [SupportedChainId.BLAST]: [
    `https://rpc.blast.io`,
    `https://rpc.envelop.is/blast`,
  ],
  [SupportedChainId.LINEA]: [
    'https://linea.decubate.com',
  ],
  [SupportedChainId.AVALANCHE]: [
    'https://rpc.ankr.com/avalanche',
    'https://ava-mainnet.public.blastapi.io/ext/bc/C/rpc',
    'https://avalanche.api.onfinality.io/public/ext/bc/C/rpc',
  ],
  [SupportedChainId.TAIKO]: [
    `https://rpc.mainnet.taiko.xyz`,
    `https://taiko.drpc.org`,
  ],
}

/**
 * JSON-RPC endpoints for balance fetches.
 */
export const BALANCE_FETCH_URLS: { [chainId: number]: string[] } = {
  [SupportedChainId.MAINNET]: [
    'https://core.gashawk.io/rpc'
  ],
  [SupportedChainId.BSC]: [
    'https://bsc-dataseed2.ninicoin.io'
  ],
  [SupportedChainId.POLYGON]: [
    'https://polygon-mainnet.public.blastapi.io'
  ],
  [SupportedChainId.MANTLE]: [
    'https://mantle-rpc.publicnode.com'
  ],
  [SupportedChainId.ARBITRUM_ONE]: [
    'https://arbitrum.drpc.org'
  ],
  [SupportedChainId.BASE]: [
    'https://mainnet.base.org'
  ],
  [SupportedChainId.OPTIMISM]: [
    'https://optimism-rpc.publicnode.com'
  ],
  [SupportedChainId.CELO]: [
    'https://forno.celo.org'
  ],
  [SupportedChainId.BLAST]: [
    'https://blast.drpc.org'
  ],
  [SupportedChainId.LINEA]: [
    'https://linea.blockpi.network/v1/rpc/public'
  ],
  [SupportedChainId.AVALANCHE]: [
    'https://api.avax.network/ext/bc/C/rpc'
  ],
  [SupportedChainId.TAIKO]: [
    'https://rpc.taiko.tools'
  ],
}