import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchBlockchainMetadata, fetchGasDataForChain, fetchUserTokenData } from './fethUserTokenData'
import { ChainIdMap } from 'utils/Types'
import { SubscribeConfig } from './reducer'

export type AllowanceEntry = { [target: string]: { allowance: string, owner: string } }

export interface SubscribedData {
  allowanceData?: AllowanceEntry
  balance: string
}

export interface GasData {
  maxFeePerGas?: string | number,
  maxPriorityFeePerGas?: string | number,
}


export interface UserChainEntry {
  timestamp: string
  nativeBalance: string | undefined
  gasData: GasData,
  tokenData: { [asset: string]: SubscribedData }
  blockNumber: number,
  account: string | undefined
  isArgentWallet: boolean | undefined
  chainId: number
}

type UniversalTokenDataResponse = { [chainId: number]: UserChainEntry }


interface FetchBalanceQueryParams {
  chainIdsToQuery?: number[],
  tokens?: ChainIdMap<string[]> // chainid -> token[]
}

export const fetchAllBalances: AsyncThunk<UniversalTokenDataResponse, FetchBalanceQueryParams | undefined, any> =
  createAsyncThunk<
    UniversalTokenDataResponse,
    FetchBalanceQueryParams | undefined,
    {
      state: { globalNetwork: { subscribeConfigs: ChainIdMap<SubscribeConfig>, networkData: ChainIdMap<any> }, }
    }>(
      'globalNetwork/fetchAllBalances',
      async (inputParmas, { getState }) => {
        const params = getState().globalNetwork.subscribeConfigs
        let chainIds = Object.keys(params).map(n => Number(n))

        // only query relevant chainIds if provuided 
        if (inputParmas?.chainIdsToQuery && inputParmas.chainIdsToQuery.length > 0) {
          chainIds = inputParmas.chainIdsToQuery
        }

        let promises: Promise<any>[] = []

        // collect promises
        for (const chainId of chainIds) {
          const chainParams = params[chainId]
          const numberId = chainId
          let tokensToGetDataFor =
            inputParmas?.tokens?.[chainId] && inputParmas?.tokens?.[chainId].length > 0 ?
              inputParmas?.tokens?.[chainId] : // if provided, only fetch input data
              chainParams.tokens // otherwise, everything
          // fetch user data
          if (chainParams.account) {
            promises.push(fetchUserTokenData(
              numberId,
              chainParams.account,
              tokensToGetDataFor ?? [],
              chainParams.approvalTargets ?? [],
              getState().globalNetwork.networkData[numberId]?.isArgentWallet,
            )
            )
          } else {
            // fetch chain data only
            promises.push(fetchBlockchainMetadata(
              numberId
            )
            )
          }
        }
        const st = performance.now()
        // resolve
        const resolved = await Promise.all(promises)
        console.info("balances: took", performance.now() - st, "for", promises.length)
        // return full resolution map
        return Object.assign(
          {},
          ...chainIds.map((id, index) => {
            return {
              [Number(id)]: resolved[index]
            }
          })
        ) as UniversalTokenDataResponse
      }
    )

export interface GasDataResponse {
  gasData: ChainIdMap<GasData>,
  chainId: number
}

export interface GasDataQuery {
  chainIds: number[]
}

export const fetchgasData: AsyncThunk<GasDataResponse, GasDataQuery, any> =
  createAsyncThunk<GasDataResponse, GasDataQuery>(
    'globalNetwork/fetchGasData',

    async ({ chainIds }) => {
      const promises: Promise<GasData>[] = []
      for (const chainId of chainIds)
        promises.push(fetchGasDataForChain(chainId))

      const resolved = await Promise.all(promises)

      return Object.assign(
        {},
        ...chainIds.map((id, index) => {
          return {
            [Number(id)]: resolved[index]
          }
        })
      )
    }
  )