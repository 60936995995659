import { Lender } from "types/lenderData/base"

/**
 * List of all the networks supported by the Uniswap Interface
 */
export enum SupportedChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,

  BSC = 56,

  ARBITRUM_ONE = 42161,
  ARBITRUM_RINKEBY = 421611,

  AVALANCHE = 43114,

  BASE = 8453,

  OPTIMISM = 10,
  OPTIMISM_GOERLI = 420,

  BLAST = 81457,

  POLYGON = 137,
  POLYGON_MUMBAI = 80001,

  CELO = 42220,
  CELO_ALFAJORES = 44787,

  MANTLE = 5000,

  LINEA = 59144,
  TAIKO = 167000
}

export const CHAIN_IDS_TO_PATH_NAMES = {
  [SupportedChainId.MAINNET]: 'mainnet',
  [SupportedChainId.ROPSTEN]: 'ropsten',
  [SupportedChainId.RINKEBY]: 'rinkeby',
  [SupportedChainId.GOERLI]: 'goerli',
  [SupportedChainId.KOVAN]: 'kovan',
  [SupportedChainId.POLYGON]: 'polygon',
  [SupportedChainId.BSC]: 'bsc',
  [SupportedChainId.POLYGON_MUMBAI]: 'polygon_mumbai',
  [SupportedChainId.CELO]: 'celo',
  [SupportedChainId.CELO_ALFAJORES]: 'celo_alfajores',
  [SupportedChainId.ARBITRUM_ONE]: 'arbitrum',
  [SupportedChainId.ARBITRUM_RINKEBY]: 'arbitrum_rinkeby',
  [SupportedChainId.AVALANCHE]: 'avalanche',
  [SupportedChainId.BASE]: 'base',
  [SupportedChainId.OPTIMISM]: 'optimism',
  [SupportedChainId.OPTIMISM_GOERLI]: 'optimism_goerli',
  [SupportedChainId.MANTLE]: 'mantle',
  [SupportedChainId.BLAST]: 'blast',
  [SupportedChainId.LINEA]: 'linea',
  [SupportedChainId.TAIKO]: 'taiko',
}

export const CHAIN_IDS_TO_LONG_NAMES = {
  [SupportedChainId.MAINNET]: 'Ethereum Mainnet',
  [SupportedChainId.ROPSTEN]: 'ropsten',
  [SupportedChainId.RINKEBY]: 'rinkeby',
  [SupportedChainId.GOERLI]: 'goerli',
  [SupportedChainId.KOVAN]: 'kovan',
  [SupportedChainId.POLYGON]: 'Polygon Mainnet',
  [SupportedChainId.BSC]: 'BNB Smart Chain',
  [SupportedChainId.POLYGON_MUMBAI]: 'polygon_mumbai',
  [SupportedChainId.CELO]: 'celo',
  [SupportedChainId.CELO_ALFAJORES]: 'celo_alfajores',
  [SupportedChainId.ARBITRUM_ONE]: 'Arbitrum One',
  [SupportedChainId.ARBITRUM_RINKEBY]: 'arbitrum_rinkeby',
  [SupportedChainId.AVALANCHE]: 'Avalanche',
  [SupportedChainId.BASE]: 'Base',
  [SupportedChainId.OPTIMISM]: 'Optimism',
  [SupportedChainId.OPTIMISM_GOERLI]: 'optimism_goerli',
  [SupportedChainId.MANTLE]: 'Mantle',
  [SupportedChainId.BLAST]: 'Blast',
  [SupportedChainId.LINEA]: 'Linea',
  [SupportedChainId.TAIKO]: 'Taiko',
}

/**
 * All additional chains on Staging but NOT on App
 */
const ADDITIONAL_STAGING_CHAINS = [
  SupportedChainId.MAINNET,
]

/**
 * All additional spot-compatible chains on Staging but NOT on App
 */
const ADDITIONAL_STAGING_CHAINS_SPOT = []
/**
 * All additional margin-compatible chains on Staging but NOT on App
 */
const ADDITIONAL_STAGING_CHAINS_MARGIN = []

/**
 * All compatible chainIds, varying dependent on staging or prod
 */
const CHAIN_IDS = [
  SupportedChainId.POLYGON,
  SupportedChainId.MANTLE,
  SupportedChainId.BSC,
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.BASE,
  SupportedChainId.OPTIMISM,
  SupportedChainId.BLAST,
  SupportedChainId.LINEA,
  SupportedChainId.TAIKO,
  SupportedChainId.AVALANCHE,
  ...process.env.REACT_APP_ENVIRONMENT === 'production' ? [] : ADDITIONAL_STAGING_CHAINS
]

/**
 * All compatible chainIds to populate the state with
 */
export const STATE_CHAINIDS = [
  ...CHAIN_IDS,
  ...ADDITIONAL_STAGING_CHAINS
]

/**
 * Lenders on Staging but not on app
 */
const ADDITIONAL_STAGING_LENDERS = [
  Lender.VENUS,
  Lender.OVIX,
  Lender.COMPOUND_V2,
  Lender.AAVE_V2,
  Lender.AURELIUS,
  Lender.TAKOTAKO
]


/**
 * All compatible lenders, varying based on staging or prod env
 */
const ALL_LENDERS = [
  Lender.AAVE_V3,
  Lender.COMPOUND_V3,
  Lender.LENDLE,
  Lender.INIT,
  Lender.MERIDIAN,
  ...process.env.REACT_APP_ENVIRONMENT === 'production' ? [] : ADDITIONAL_STAGING_LENDERS
]

/**
 * Gets the chainIds based on verison
 * @returns chainId array
 */
export const getAvailableChainIds = () => CHAIN_IDS

/**
 * ChainIds shown in network/lender selector
 */
const NETWORK_SELECTOR_CHAINS = [
  SupportedChainId.POLYGON,
  SupportedChainId.MANTLE,
  SupportedChainId.TAIKO,
  ...process.env.REACT_APP_ENVIRONMENT === 'production' ? [] : ADDITIONAL_STAGING_CHAINS
]

const MARGIN_CHAIN_IDS = [
  SupportedChainId.MANTLE,
  SupportedChainId.POLYGON,
  SupportedChainId.TAIKO,
  ...process.env.REACT_APP_ENVIRONMENT === 'production' ? [] : ADDITIONAL_STAGING_CHAINS_MARGIN
]

const SPOT_CHAIN_IDS = [
  SupportedChainId.MANTLE,
  SupportedChainId.POLYGON,
  SupportedChainId.BSC,
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.BASE,
  SupportedChainId.OPTIMISM,
  SupportedChainId.BLAST,
  SupportedChainId.LINEA,
  SupportedChainId.TAIKO,
  SupportedChainId.AVALANCHE,
  ...process.env.REACT_APP_ENVIRONMENT === 'production' ? [] : ADDITIONAL_STAGING_CHAINS_SPOT
]

/**
 * Get networks for the respective version to be shown on the selector
 * @returns array of chainIds 
 */
export const getAvailableNetworkChainIds = () => NETWORK_SELECTOR_CHAINS

export const getAvailableMarginChainIds = () => MARGIN_CHAIN_IDS

export const getAvailableSpotChainIds = () => SPOT_CHAIN_IDS
/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
  (id) => typeof id === 'number'
) as SupportedChainId[]

export function isSupportedChain(chainId: number | null | undefined): chainId is SupportedChainId {
  return getAvailableChainIds().includes(Number(chainId))
}

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [
  SupportedChainId.MAINNET,
  SupportedChainId.POLYGON,
  SupportedChainId.CELO,
  SupportedChainId.OPTIMISM,
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.BASE,
  SupportedChainId.BLAST,
  SupportedChainId.LINEA,
  SupportedChainId.AVALANCHE,
  SupportedChainId.TAIKO,
]

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [
  SupportedChainId.MAINNET,
  SupportedChainId.ROPSTEN,
  SupportedChainId.RINKEBY,
  SupportedChainId.GOERLI,
  SupportedChainId.KOVAN,
  SupportedChainId.POLYGON,
  SupportedChainId.BSC,
  SupportedChainId.POLYGON_MUMBAI,
  SupportedChainId.CELO,
  SupportedChainId.CELO_ALFAJORES,
  SupportedChainId.AVALANCHE,
] as const

export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number]

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS = [
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.ARBITRUM_RINKEBY,
  SupportedChainId.OPTIMISM,
  SupportedChainId.OPTIMISM_GOERLI,
  SupportedChainId.BASE,
  SupportedChainId.BLAST,
  SupportedChainId.LINEA,
  SupportedChainId.TAIKO,
] as const

export type SupportedL2ChainId = typeof L2_CHAIN_IDS[number]

export const DEFAULT_CHAINID = SupportedChainId.MANTLE

const LENDERS_PER_CHAIN = {
  [SupportedChainId.MAINNET]: [Lender.COMPOUND_V2],
  [SupportedChainId.POLYGON]: [Lender.AAVE_V3, Lender.COMPOUND_V3],
  [SupportedChainId.GOERLI]: [Lender.COMPOUND_V2, Lender.AAVE_V3],
  [SupportedChainId.POLYGON_MUMBAI]: [Lender.AAVE_V3, Lender.COMPOUND_V3, Lender.OVIX],
  [SupportedChainId.MANTLE]: [Lender.LENDLE, Lender.INIT],
  [SupportedChainId.BSC]: [Lender.VENUS],
  [SupportedChainId.ARBITRUM_ONE]: [Lender.AAVE_V3, Lender.COMPOUND_V3],
  [SupportedChainId.BASE]: [Lender.AAVE_V3, Lender.COMPOUND_V3],
  [SupportedChainId.AVALANCHE]: [Lender.AAVE_V3],
  [SupportedChainId.OPTIMISM]: [Lender.AAVE_V3, Lender.COMPOUND_V3],
  [SupportedChainId.BLAST]: [],
  [SupportedChainId.LINEA]: [],
  [SupportedChainId.TAIKO]: [Lender.MERIDIAN],
}

const ADDITIONAL_STAGING_LENDERS_PER_CHAIN = {
  [SupportedChainId.MAINNET]: [],
  [SupportedChainId.POLYGON]: [Lender.AAVE_V2],
  [SupportedChainId.GOERLI]: [],
  [SupportedChainId.POLYGON_MUMBAI]: [],
  [SupportedChainId.MANTLE]: [Lender.AURELIUS],
  [SupportedChainId.BSC]: [],
  [SupportedChainId.ARBITRUM_ONE]: [],
  [SupportedChainId.BASE]: [],
  [SupportedChainId.AVALANCHE]: [],
  [SupportedChainId.OPTIMISM]: [],
  [SupportedChainId.BLAST]: [],
  [SupportedChainId.LINEA]: [],
  [SupportedChainId.TAIKO]: [Lender.TAKOTAKO],
}

export const SUPPORTED_LENDERS_PER_CHAIN = {
  [SupportedChainId.MAINNET]: [],
  [SupportedChainId.POLYGON]: [Lender.AAVE_V3, Lender.COMPOUND_V3],
  [SupportedChainId.GOERLI]: [],
  [SupportedChainId.POLYGON_MUMBAI]: [],
  [SupportedChainId.MANTLE]: [Lender.LENDLE, Lender.INIT],
  [SupportedChainId.BSC]: [],
  [SupportedChainId.ARBITRUM_ONE]: [],
  [SupportedChainId.BASE]: [],
  [SupportedChainId.AVALANCHE]: [],
  [SupportedChainId.OPTIMISM]: [],
  [SupportedChainId.BLAST]: [],
  [SupportedChainId.LINEA]: [],
  [SupportedChainId.TAIKO]: [Lender.MERIDIAN],
}

export const getLendersOnChain = (chainId: number) => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    return LENDERS_PER_CHAIN[chainId]?.concat(ADDITIONAL_STAGING_LENDERS_PER_CHAIN[chainId])
  }
  return LENDERS_PER_CHAIN[chainId]
}

export const lenderIsOnChain = (chainId: number, lender?: Lender) => lender ? getLendersOnChain(chainId).includes(lender) : false

const ROUTE_TO_CHAIN_MARGIN: { [cid: string]: number } = {
  'goerli': SupportedChainId.GOERLI,
  'polygon': SupportedChainId.POLYGON,
  'polygon-mumbai': SupportedChainId.POLYGON_MUMBAI,
  'mainnet': SupportedChainId.MAINNET,
  // 'bsc': SupportedChainId.BSC,
  'mantle': SupportedChainId.MANTLE,
  'taiko': SupportedChainId.TAIKO,
  // 'arbitrum': SupportedChainId.ARBITRUM_ONE,
  // 'base': SupportedChainId.BASE,
  // 'avalanche': SupportedChainId.AVALANCHE,
  // 'optimism': SupportedChainId.OPTIMISM,
  // 'blast': SupportedChainId.BLAST,
  // 'linea': SupportedChainId.LINEA,
}

const ROUTE_TO_CHAIN_SPOT: { [cid: string]: number } = {
  'goerli': SupportedChainId.GOERLI,
  'polygon': SupportedChainId.POLYGON,
  'polygon-mumbai': SupportedChainId.POLYGON_MUMBAI,
  'mainnet': SupportedChainId.MAINNET,
  'bsc': SupportedChainId.BSC,
  'mantle': SupportedChainId.MANTLE,
  'arbitrum': SupportedChainId.ARBITRUM_ONE,
  'base': SupportedChainId.BASE,
  'avalanche': SupportedChainId.AVALANCHE,
  'optimism': SupportedChainId.OPTIMISM,
  'blast': SupportedChainId.BLAST,
  'linea': SupportedChainId.LINEA,
  'taiko': SupportedChainId.TAIKO,
}

export const validateRouteToChain = (route: string) => {
  let chainId = ROUTE_TO_CHAIN_SPOT[route]
  if (!chainId) chainId = preValidateChain(route)
  if (chainId && getAvailableNetworkChainIds().includes(chainId)) return chainId
  return 0
}

export const validateRouteToChainMargin = (route: string) => {
  let chainId = ROUTE_TO_CHAIN_MARGIN[route]
  if (!chainId) chainId = preValidateChain(route)
  if (chainId && getAvailableMarginChainIds().includes(chainId)) return chainId
  return 0
}

export const validateRouteToChainSpot = (route: string) => {
  let chainId = ROUTE_TO_CHAIN_SPOT[route]
  if (!chainId) chainId = preValidateChain(route)
  if (chainId && getAvailableSpotChainIds().includes(chainId)) return chainId
  return 0
}

const preValidateChain = (query: string) => {
  const lowerCase = query.toLowerCase()
  if (
    lowerCase.includes('poly') ||
    lowerCase.includes('gon')
  ) return SupportedChainId.POLYGON

  if (
    lowerCase.includes('ma') ||
    lowerCase.includes('tl') ||
    lowerCase.includes('tle')
  ) return SupportedChainId.MANTLE

  if (
    lowerCase.includes('net')
  ) return SupportedChainId.MAINNET

  if (
    lowerCase.includes('smart') ||
    lowerCase.includes('bsc') ||
    lowerCase.includes('chain')
  ) return SupportedChainId.BSC

  if (
    lowerCase.includes('arbi') ||
    lowerCase.includes('trum')
  ) return SupportedChainId.ARBITRUM_ONE

  if (
    lowerCase.includes('ba') ||
    lowerCase.includes('se')
  ) return SupportedChainId.BASE

  if (
    lowerCase.includes('tai') ||
    lowerCase.includes('iko')
  ) return SupportedChainId.BASE


  if (
    lowerCase.includes('ava') ||
    lowerCase.includes('lan') ||
    lowerCase.includes('che')
  ) return SupportedChainId.AVALANCHE

  if (
    lowerCase.includes('opti') ||
    lowerCase.includes('mism')
  ) return SupportedChainId.OPTIMISM

  if (
    lowerCase.includes('bla') ||
    lowerCase.includes('st')
  ) return SupportedChainId.BLAST

  if (
    lowerCase.includes('LI') ||
    lowerCase.includes('NEA')
  ) return SupportedChainId.LINEA

  return 0
}

export const LENDER_TO_ROUTE: { [lender in Lender]: string } = {
  [Lender.AAVE_V3]: 'aave-v3',
  [Lender.AAVE_V2]: 'aave-v2',
  [Lender.AURELIUS]: 'aurelius',
  [Lender.YLDR]: 'yldr',
  [Lender.COMPOUND_V2]: 'compound-v2',
  [Lender.COMPOUND_V3]: 'compound-v3',
  [Lender.OVIX]: 'vix',
  [Lender.VENUS]: 'venus',
  [Lender.LENDLE]: 'lendle',
  [Lender.INIT]: 'init',
  [Lender.MERIDIAN]: 'meridian',
  [Lender.TAKOTAKO]: 'takotako',
}

const GENERAL_ROUTE_TO_LENDER: { [lender: string]: Lender } = {
  'aave-v3': Lender.AAVE_V3,
  'aave-v2': Lender.AAVE_V2,
  'lendle': Lender.LENDLE,
  'compound-v2': Lender.COMPOUND_V2,
  'compound-v3': Lender.COMPOUND_V3,
  'init': Lender.INIT,
  'meridian': Lender.MERIDIAN,
  'aurelius': Lender.AURELIUS,
  'takotako': Lender.TAKOTAKO
}

export const ROUTE_TO_LENDER: { [lender: string]: Lender } = {
  ...GENERAL_ROUTE_TO_LENDER,
  ...process.env.REACT_APP_ENVIRONMENT === 'production' ? {} : { 'venus': Lender.VENUS, }
}

export const validateRouteToLender = (route: string) => {
  let lender: Lender | undefined = ROUTE_TO_LENDER[route]
  if (!lender) lender = preValidateLender(route)
  if (lender && ALL_LENDERS.includes(lender)) return lender
  return Lender.AAVE_V3
}

export const preValidateLender = (query: string) => {
  const lowerCase = query.toLowerCase()
  if (
    lowerCase.includes('aav') ||
    lowerCase.includes('ve')
  ) return Lender.AAVE_V3

  if (
    lowerCase.includes('len') ||
    lowerCase.includes('dle')
  ) return Lender.LENDLE

  if (
    lowerCase.includes('merid') ||
    lowerCase.includes('dian')
  ) return Lender.MERIDIAN

  if (
    lowerCase.includes('com') ||
    lowerCase.includes('pou') ||
    lowerCase.includes('und')
  ) return Lender.COMPOUND_V3

  if (
    lowerCase.includes('aure') ||
    lowerCase.includes('lius')
  ) return Lender.AURELIUS

  if (
    lowerCase.includes('tako')
  ) return Lender.TAKOTAKO

  return undefined
}

export const INIT_MODES = [1, 2, 3, 4, 5, 6]

export const INIT_EMODE_LABELS: { [mode: number]: string } = {
  [1]: 'Default',
  [2]: 'Blue Chip Volatile',
  [3]: 'Stablecoins',
  [4]: 'ETH correlated',
  [5]: 'Ethena',
  [6]: 'BTC',
}

const AAVE_EMODE_LABELS: { [chainId: number]: { [mode: number]: string } } = {
  [SupportedChainId.POLYGON]: {
    [0]: 'Default',
    [1]: 'Stablecoins',
    [2]: 'MATIC correlated',
    [3]: 'ETH correlated',
  }
}

/**
 * Get the hard-coded label for a given mode per lender and chainId
 * @returns the label or mode number
 */
export const getModeLabel = (chainId: number, lender: Lender, mode: number) => {
  if (lender === Lender.AAVE_V3) return AAVE_EMODE_LABELS[chainId]?.[mode] ?? mode
  if (lender === Lender.INIT) return INIT_EMODE_LABELS[mode] ?? mode
  return null
}
