import { BPS, TOKEN_META } from "constants/1delta";
import { getLenderAssets } from "constants/getAssets";
import { getLenderTokenAddresses } from "hooks/lenders/lenderAddressGetter";
import { AdditionalYields } from "state/oracles/reducer";
import { Lender } from "types/lenderData/base";
import { formatAaveRawApyToApr } from "utils/1delta/generalFormatters";
import { parseRawAmount } from "utils/tableUtils/prices";
import {
  AaveV3GeneralPublicResponse,
  AaveV3GetreserveConfigDataIndexes,
  AaveV3GetReservesIndexes
} from "./types";
import { AAVE_V3_EMODES } from "./publicCallBuild";
import { toOracleKey } from "types/1delta";

export const getAaveV3ReservesDataConverter = (
  lender: Lender,
  chainId: number,
  prices: { [asset: string]: number },
  additionalYields: AdditionalYields
): [(data: any[]) => AaveV3GeneralPublicResponse | undefined, number] => {
  switch (lender) {
    /** AAVE V3 style with rewards from state */
    case Lender.AAVE_V3: {
      const tokenDict = getLenderTokenAddresses(chainId, lender)
      const assetsToQuery = getLenderAssets(chainId, lender)
      const expectedNumberOfCalls = assetsToQuery.length * 5 + 5 * 3 + 2

      return [
        (data: any[]) => {
          if (data.length !== expectedNumberOfCalls) {
            return undefined
          }
          const emodeDataResult = data.slice(assetsToQuery.length * 5, assetsToQuery.length * 5 + 5 * 3)
          const resultReserves: any = {}
          const resultConfig: any = {}

          const reservesList = data[expectedNumberOfCalls - 2]
          const decimalsCeiling = data[expectedNumberOfCalls - 1]

          let eModeConfigs = {}
          let collateralBitmap = {}
          let debtBitmap = {}
          AAVE_V3_EMODES.map((mode, index) => {
            const rawCfg = emodeDataResult[3 * index]
            if (mode === 0 || rawCfg.label !== '')
              eModeConfigs[mode] = {
                category: mode,
                label: rawCfg.label,
                borrowCollateralFactor: rawCfg.ltv / BPS,
                collateralFactor: rawCfg.liquidationThreshold / BPS,
                borrowFactor: 1
              }
            debtBitmap[mode] = emodeDataResult[3 * index + 1]
            collateralBitmap[mode] = emodeDataResult[3 * index + 2]
          })

          // we get the reserves list to obtain the index per reserve
          const lowerReservesList = (reservesList as string[]).map(a => a.toLowerCase())

          for (let i = 0; i < assetsToQuery.length; i++) {
            const asset = assetsToQuery[i]

            const reserveData = data[i * 5]
            const configData = data[i * 5 + 1]
            /** Unused so far */
            // const siloedBorrowing = data[i * 5 + 2]
            const reserveCaps = data[i * 5 + 3]
            const debtCeiling = data[i * 5 + 4]


            const decimals = TOKEN_META[asset]?.decimals ?? 18
            const totalDeposits = parseRawAmount(reserveData?.[AaveV3GetReservesIndexes.totalAToken].toString(), decimals)
            const totalDebtStable = parseRawAmount(reserveData?.[AaveV3GetReservesIndexes.totalStableDebt].toString(), decimals)
            const totalDebt = parseRawAmount(reserveData?.[AaveV3GetReservesIndexes.totalVariableDebt].toString(), decimals)
            const liquidity = totalDeposits - totalDebt - totalDebtStable
            const price = prices[toOracleKey(asset)] ?? 1

            resultReserves[asset] = {
              // raw amounts
              totalDeposits,
              totalDebtStable,
              totalDebt,
              totalLiquidity: liquidity,
              // USD amounts
              totalDepositsUSD: totalDeposits * price,
              totalDebtStableUSD: totalDebtStable * price,
              totalDebtUSD: totalDebt * price,
              totalLiquidityUSD: liquidity * price,
              // rates
              depositRate: formatAaveRawApyToApr(reserveData?.[AaveV3GetReservesIndexes.liquidityRate].toString()),
              variableBorrowRate: formatAaveRawApyToApr(reserveData?.[AaveV3GetReservesIndexes.variableBorrowRate].toString()),
              stableBorrowRate: formatAaveRawApyToApr(reserveData?.[AaveV3GetReservesIndexes.stableBorrowRate].toString()),

              stakingYield: additionalYields.intrinsicYields[asset] ?? 0,
              // rewards
              rewards: {},
            }
            const activeEmodes = AAVE_V3_EMODES.map(mode => isReserveEnabledOnBitmap(debtBitmap[mode], lowerReservesList.indexOf(tokenDict[asset].toLowerCase())) ? mode : -1).filter(m => m > 0)
            const eModeCategory = activeEmodes.length === 0 ? 0 : activeEmodes[0] // Number(emodeResult[index].toString())

            resultConfig[asset] = {
              decimals: Number(configData?.[AaveV3GetreserveConfigDataIndexes.decimals]),

              config: {
                ...populateEModes(Number(configData?.[AaveV3GetreserveConfigDataIndexes.ltv]) / BPS, Number(configData?.[AaveV3GetreserveConfigDataIndexes.liquidationThreshold]) / BPS),
                ...(eModeCategory !== 0 ? {
                  [eModeCategory]: {
                    modeId: eModeCategory,
                    borrowCollateralFactor: eModeConfigs[eModeCategory].borrowCollateralFactor,
                    collateralFactor: eModeConfigs[eModeCategory].collateralFactor,
                    borrowFactor: 1
                  }
                } : {})

              },

              // flags
              collateralActive: configData?.[AaveV3GetreserveConfigDataIndexes.usageAsCollateralEnabled],
              borrowingEnabled: configData?.[AaveV3GetreserveConfigDataIndexes.borrowingEnabled],
              hasStable: configData?.[AaveV3GetreserveConfigDataIndexes.stableBorrowRateEnabled],
              isActive: configData?.[AaveV3GetreserveConfigDataIndexes.isActive],
              isFrozen: configData?.[AaveV3GetreserveConfigDataIndexes.isFrozen],

              // eMode
              eMode: eModeConfigs[eModeCategory],

              // caps
              borrowCap: Number(reserveCaps[0]?.toString()),
              supplyCap: Number(reserveCaps[1]?.toString()),
              debtCeiling: parseRawAmount(debtCeiling[i]?.toString(), Number(decimalsCeiling?.toString()))
            }
          }
          return {
            data: resultReserves,
            config: resultConfig,
            chainId,
            eModes: eModeConfigs
          }
        },
        expectedNumberOfCalls,
      ]
    }
    default:
      return [
        () => undefined,
        0
      ]
  }
}

const populateEModes = (borrowCollateralFactor: number, collateralFactor: number) => {
  return Object.assign({}, ...AAVE_V3_EMODES.map(e => {
    return {
      [e]: {
        modeId: e,
        borrowCollateralFactor,
        collateralFactor,
        borrowFactor: 1
      }
    }
  }))
}


/** Replica of aave's bitmap checker */
function isReserveEnabledOnBitmap(
  bitmap: bigint,
  reserveIndex: number
): boolean {
  return ((bitmap >> BigInt(reserveIndex)) & 1n) !== 0n;
}
