import { SupportedChainId } from 'constants/chains'
import { NATIVE_ASSET, SupportedAssets } from 'types/1delta'
import { AddressDictionary } from './addresses'
import { Currency, Token } from '@1delta/base-sdk'
import { NATIVE_SYMBOL, TOKEN_META } from 'constants/1delta'
import { nativeOnChain } from 'constants/tokens'

export const addressesTokens: AddressDictionary = {
  [SupportedAssets.DAI]: {
    [SupportedChainId.MAINNET]: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    [SupportedChainId.POLYGON]: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    [SupportedChainId.BSC]: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    [SupportedChainId.ARBITRUM_ONE]: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
    [SupportedChainId.BASE]: '0x50c5725949a6f0c72e6c4a641f24049a917db0cb',
    [SupportedChainId.OPTIMISM]: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    [SupportedChainId.LINEA]: '0x4af15ec2a0bd43db75dd04e62faa3b8ef36b00d5',
  },
  [SupportedAssets.CBETH]: {
    [SupportedChainId.BASE]: '0x2ae3f1ec7f1f5012cfeab0185bfc7aa3cf0dec22'
  },
  [SupportedAssets.UNI]: {
    [SupportedChainId.MAINNET]: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
  },
  [SupportedAssets.USDCE]: {
    [SupportedChainId.MAINNET]: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    [SupportedChainId.POLYGON]: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    [SupportedChainId.ARBITRUM_ONE]: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    [SupportedChainId.OPTIMISM]: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
    [SupportedChainId.LINEA]: '0x176211869ca2b568f2a7d4ee941e073a821ee1ff',
    [SupportedChainId.TAIKO]: '0x19e26B0638bf63aa9fa4d14c6baF8D52eBE86C5C',
  },
  [SupportedAssets.USDC]: {
    [SupportedChainId.POLYGON]: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
    [SupportedChainId.MANTLE]: '0x09Bc4E0D864854c6aFB6eB9A9cdF58aC190D0dF9',
    [SupportedChainId.ARBITRUM_ONE]: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
    [SupportedChainId.BASE]: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
    [SupportedChainId.AVALANCHE]: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    [SupportedChainId.OPTIMISM]: '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
    [SupportedChainId.TAIKO]: '0x07d83526730c7438048D55A4fc0b850e2aaB6f0b',
  },
  [SupportedAssets.BUSDC]: {
    [SupportedChainId.BSC]: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
  },
  [SupportedAssets.USDT]: {
    [SupportedChainId.MAINNET]: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    [SupportedChainId.POLYGON]: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    [SupportedChainId.MANTLE]: '0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE',
    [SupportedChainId.ARBITRUM_ONE]: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    [SupportedChainId.OPTIMISM]: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
    [SupportedChainId.LINEA]: '0xa219439258ca9da29e9cc4ce5596924745e12b93',
    [SupportedChainId.AVALANCHE]: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
    [SupportedChainId.TAIKO]: '0x2def195713cf4a606b49d07e520e22c17899a736'
  },
  [SupportedAssets.BUSDT]: {
    [SupportedChainId.BSC]: '0x55d398326f99059fF775485246999027B3197955'
  },
  [SupportedAssets.WBTC]: {
    [SupportedChainId.MAINNET]: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    [SupportedChainId.POLYGON]: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
    [SupportedChainId.MANTLE]: '0xCAbAE6f6Ea1ecaB08Ad02fE02ce9A44F09aebfA2',
    [SupportedChainId.ARBITRUM_ONE]: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
    [SupportedChainId.AVALANCHE]: '0x50b7545627a5162f82a992c33b87adc75187b218',
    [SupportedChainId.OPTIMISM]: '0x68f180fcCe6836688e9084f035309E29Bf0A2095',
    [SupportedChainId.TAIKO]: '0xc4c410459fbaf8f7f86b6cee52b4fa1282ff9704'
  },
  [SupportedAssets.COMP]: {
    [SupportedChainId.MAINNET]: '0xc00e94Cb662C3520282E6f5717214004A7f26888',
    [SupportedChainId.POLYGON]: '0x8505b9d2254A7Ae468c0E9dd10Ccea3A837aef5c'
  },
  [SupportedAssets.ZRX]: {
    [SupportedChainId.MAINNET]: '0xE41d2489571d322189246DaFA5ebDe1F4699F498',
  },
  [SupportedAssets.YFI]: {
    [SupportedChainId.MAINNET]: '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e',
  },
  [SupportedAssets.WBTC2]: {
    [SupportedChainId.MAINNET]: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  },
  [SupportedAssets.USDP]: {
    [SupportedChainId.MAINNET]: '0x8E870D67F660D95d5be530380D0eC0bd388289E1',
  },
  [SupportedAssets.FEI]: {
    [SupportedChainId.MAINNET]: '0x956F47F50A910163D8BF957Cf5846D573E7f87CA',
  },
  [SupportedAssets.LINK]: {
    [SupportedChainId.MAINNET]: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
    [SupportedChainId.POLYGON]: '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39',
    [SupportedChainId.BSC]: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD'
  },
  [SupportedAssets.MKR]: {
    [SupportedChainId.MAINNET]: '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2',
  },
  [SupportedAssets.REP]: {
    [SupportedChainId.MAINNET]: '0x1985365e9f78359a9B6AD760e32412f4a445E862',
  },
  [SupportedAssets.AAVE]: {
    [SupportedChainId.MAINNET]: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
    [SupportedChainId.POLYGON]: '0xD6DF932A45C0f255f85145f286eA0b292B21C90B',
    [SupportedChainId.BSC]: '0xfb6115445Bff7b52FeB98650C87f44907E58f802'
  },
  [SupportedAssets.BAT]: {
    [SupportedChainId.MAINNET]: '0x0D8775F648430679A709E98d2b0Cb6250d2887EF',
  },
  [SupportedAssets.AGEUR]: {
    [SupportedChainId.POLYGON]: '0xE0B52e49357Fd4DAf2c15e02058DCE6BC0057db4',
    [SupportedChainId.MAINNET]: '0x1a7e4e63778B4f12a199C062f3eFdD288afCBce8'
  },
  [SupportedAssets.EURS]: {
    [SupportedChainId.POLYGON]: '0xe111178a87a3bff0c8d18decba5798827539ae99',
    [SupportedChainId.MAINNET]: '0xdB25f211AB05b1c97D595516F45794528a807ad8'
  },
  [SupportedAssets.JEUR]: {
    [SupportedChainId.POLYGON]: '0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c',
  },
  [SupportedAssets.MAI]: {
    [SupportedChainId.POLYGON]: '0xa3fa99a148fa48d14ed51d610c367c61876997f1',
  },
  [SupportedAssets.BAL]: {
    [SupportedChainId.POLYGON]: '0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3',
    [SupportedChainId.MAINNET]: '0xba100000625a3754423978a60c9317c58a424e3D',
  },
  [SupportedAssets.CRV]: {
    [SupportedChainId.MAINNET]: '0xD533a949740bb3306d119CC777fa900bA034cd52',
    [SupportedChainId.POLYGON]: '0x172370d5Cd63279eFa6d502DAB29171933a610AF',
  },
  [SupportedAssets.DPI]: {
    [SupportedChainId.POLYGON]: '0x85955046df4668e1dd369d2de9f3aeb98dd2a369',
    [SupportedChainId.MAINNET]: '0x1494CA1F11D487c2bBe4543E90080AeBa4BA3C2b'
  },
  [SupportedAssets.GHST]: {
    [SupportedChainId.POLYGON]: '0x385eeac5cb85a38a9a07a70c73e0a3271cfb54a7',
    [SupportedChainId.MAINNET]: '0x3F382DbD960E3a9bbCeaE22651E88158d2791550'
  },
  [SupportedAssets.MATICX]: {
    [SupportedChainId.POLYGON]: '0xfa68fb4628dff1028cfec22b4162fccd0d45efb6',
  },
  [SupportedAssets.STMATIC]: {
    [SupportedChainId.POLYGON]: '0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4',
  },
  [SupportedAssets.SUSHI]: {
    [SupportedChainId.POLYGON]: '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a',
    [SupportedChainId.MAINNET]: '0x6B3595068778DD592e39A122f4f5a5cF09C90fE2'
  },
  [SupportedAssets.WETH]: {
    [SupportedChainId.POLYGON]: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    [SupportedChainId.MAINNET]: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    [SupportedChainId.BSC]: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    [SupportedChainId.MANTLE]: '0xdEAddEaDdeadDEadDEADDEAddEADDEAddead1111',
    [SupportedChainId.ARBITRUM_ONE]: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    [SupportedChainId.BASE]: '0x4200000000000000000000000000000000000006',
    [SupportedChainId.AVALANCHE]: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
    [SupportedChainId.OPTIMISM]: '0x4200000000000000000000000000000000000006',
    [SupportedChainId.BLAST]: '0x4300000000000000000000000000000000000004',
    [SupportedChainId.LINEA]: '0xe5d7c2a44ffddf6b295a15c148167daaaf5cf34f',
    [SupportedChainId.TAIKO]: '0xA51894664A773981C6C112C43ce576f315d5b1B6',
  },
  [SupportedAssets.WMATIC]: {
    [SupportedChainId.POLYGON]: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    [SupportedChainId.MAINNET]: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
    [SupportedChainId.BSC]: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD'
  },
  [SupportedAssets.VGHST]: {
    [SupportedChainId.POLYGON]: '0x51195e21BDaE8722B29919db56d95Ef51FaecA6C'
  },
  [SupportedAssets.GDAI]: {
    [SupportedChainId.POLYGON]: '0x91993f2101cc758D0dEB7279d41e880F7dEFe827'
  },
  [SupportedAssets.WSTETH]: {
    [SupportedChainId.POLYGON]: '0x03b54A6e9a984069379fae1a4fC4dBAE93B3bCCD',
    [SupportedChainId.MAINNET]: '0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0',
    [SupportedChainId.MANTLE]: '0x636D4073738C071326Aa70c9e5DB7C334bEb87bE'
  },
  [SupportedAssets.FBTC]: {
    [SupportedChainId.MANTLE]: '0xc96de26018a54d51c097160568752c4e3bd6c364',
  },
  // Wrapped mantle
  [SupportedAssets.WMNT]: {
    [SupportedChainId.MANTLE]: '0x78c1b0c915c4faa5fffa6cabf0219da63d7f4cb8'
  },
  // Mantle staked Ether
  [SupportedAssets.METH]: {
    [SupportedChainId.MANTLE]: '0xcda86a272531e8640cd7f1a92c01839911b90bb0'
  },

  [SupportedAssets.USDY]: {
    [SupportedChainId.MANTLE]: '0x5bE26527e817998A7206475496fDE1E68957c5A6'
  },
  [SupportedAssets.USDE]: {
    [SupportedChainId.MANTLE]: '0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34'
  },
  [SupportedAssets.OAU]: {
    [SupportedChainId.MANTLE]: '0x6e20E155819f0ee08d1291b0b9889b0e011b8224'
  },
  // venus assets
  [SupportedAssets.WBNB]: {
    [SupportedChainId.BSC]: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
  },
  [SupportedAssets.ADA]: {
    [SupportedChainId.BSC]: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47'
  },
  [SupportedAssets.BCH]: {
    [SupportedChainId.BSC]: '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf'
  },
  [SupportedAssets.BETH]: {
    [SupportedChainId.BSC]: '0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B'
  },
  [SupportedAssets.BTCB]: {
    [SupportedChainId.BSC]: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c'
  },
  [SupportedAssets.BUSD]: {
    [SupportedChainId.BSC]: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
  },
  [SupportedAssets.CAKE]: {
    [SupportedChainId.BSC]: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
  },
  [SupportedAssets.CAN]: {
    [SupportedChainId.BSC]: '0x20bff4bbEDa07536FF00e073bd8359E5D80D733d'
  },
  [SupportedAssets.DOGE]: {
    [SupportedChainId.BSC]: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43'
  },
  [SupportedAssets.DOT]: {
    [SupportedChainId.BSC]: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402'
  },
  [SupportedAssets.FIL]: {
    [SupportedChainId.BSC]: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153'
  },
  [SupportedAssets.LTC]: {
    [SupportedChainId.BSC]: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94'
  },
  [SupportedAssets.LUNA]: {
    [SupportedChainId.BSC]: '0x156ab3346823B651294766e23e6Cf87254d68962'
  },
  [SupportedAssets.SXP]: {
    [SupportedChainId.BSC]: '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A'
  },
  [SupportedAssets.TRX]: {
    [SupportedChainId.BSC]: '0xCE7de646e7208a4Ef112cb6ed5038FA6cC6b12e3'
  },
  [SupportedAssets.TRXOLD]: {
    [SupportedChainId.BSC]: '0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B'
  },
  [SupportedAssets.TUSD]: {
    [SupportedChainId.BSC]: '0x40af3827F39D0EAcBF4A168f8D4ee67c121D11c9'
  },
  [SupportedAssets.TUSDOLD]: {
    [SupportedChainId.BSC]: '0x14016E85a25aeb13065688cAFB43044C2ef86784'
  },
  [SupportedAssets.UST]: {
    [SupportedChainId.BSC]: '0x3d4350cD54aeF9f9b2C29435e0fa809957B3F30a'
  },
  [SupportedAssets.VAI]: {
    [SupportedChainId.BSC]: '0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7'
  },
  [SupportedAssets.VRT]: {
    [SupportedChainId.BSC]: '0x5f84ce30dc3cf7909101c69086c50de191895883'
  },
  [SupportedAssets.WBETH]: {
    [SupportedChainId.BSC]: '0xa2e3356610840701bdf5611a53974510ae27e2e1'
  },
  [SupportedAssets.XRP]: {
    [SupportedChainId.BSC]: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE'
  },
  [SupportedAssets.XVS]: {
    [SupportedChainId.BSC]: '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63'
  },
  [SupportedAssets.USDB]: {
    [SupportedChainId.BLAST]: '0x4300000000000000000000000000000000000003'
  },
  [SupportedAssets.BLAST]: {
    [SupportedChainId.BLAST]: '0xb1a5700fA2358173Fe465e6eA4Ff52E36e88E2ad'
  },
  [SupportedAssets.ANDY]: {
    [SupportedChainId.BLAST]: '0xd43D8aDAC6A4C7d9Aeece7c3151FcA8f23752cf8'
  },
  [SupportedAssets.WAVAX]: {
    [SupportedChainId.AVALANCHE]: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7'
  },
  [SupportedAssets.USDTE]: {
    [SupportedChainId.TAIKO]: '0x9c2dc7377717603eb92b2655c5f2e7997a4945bd'
  },
  [SupportedAssets.TAIKO]: {
    [SupportedChainId.TAIKO]: '0xa9d23408b9ba935c230493c40c73824df71a0975'
  },
}
/**
 * Converts an asset enum to its address
 * @param asset asset enum
 * @param chainId network
 * @returns address or native symbol
 */
export const assetToAddress = (asset: SupportedAssets, chainId: number) => {
  if (asset === NATIVE_ASSET[chainId]) return NATIVE_SYMBOL[chainId]
  return addressesTokens[asset]?.[chainId]
}


/** get a token / native from assetId */
export function assetToToken(asset: SupportedAssets, chainId: number) {
  const meta = TOKEN_META[asset]
  if (!meta) return undefined
  const address = assetToAddress(asset, chainId)
  if (!address) return undefined
  if (address === NATIVE_SYMBOL[chainId]) return nativeOnChain(chainId)
  return new Token(chainId, address as any, meta.decimals, meta.symbol, meta.name,)
}


export const ADDRESS_TO_CHAINID_TO_ASSET = {
  [SupportedChainId.MAINNET]: {
    "0x6b175474e89094c44da98b954eedeac495271d0f": SupportedAssets.DAI,
    "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984": SupportedAssets.UNI,
    "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48": SupportedAssets.USDCE,
    "0xdac17f958d2ee523a2206206994597c13d831ec7": SupportedAssets.USDT,
    "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599": SupportedAssets.WBTC2,
    "0xc00e94cb662c3520282e6f5717214004a7f26888": SupportedAssets.COMP,
    "0xe41d2489571d322189246dafa5ebde1f4699f498": SupportedAssets.ZRX,
    "0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e": SupportedAssets.YFI,
    "0x8e870d67f660d95d5be530380d0ec0bd388289e1": SupportedAssets.USDP,
    "0x956f47f50a910163d8bf957cf5846d573e7f87ca": SupportedAssets.FEI,
    "0x514910771af9ca656af840dff83e8264ecf986ca": SupportedAssets.LINK,
    "0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2": SupportedAssets.MKR,
    "0x1985365e9f78359a9b6ad760e32412f4a445e862": SupportedAssets.REP,
    "0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9": SupportedAssets.AAVE,
    "0x0d8775f648430679a709e98d2b0cb6250d2887ef": SupportedAssets.BAT,
    "0x1a7e4e63778b4f12a199c062f3efdd288afcbce8": SupportedAssets.AGEUR,
    "0xdb25f211ab05b1c97d595516f45794528a807ad8": SupportedAssets.EURS,
    "0xba100000625a3754423978a60c9317c58a424e3d": SupportedAssets.BAL,
    "0xd533a949740bb3306d119cc777fa900ba034cd52": SupportedAssets.CRV,
    "0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b": SupportedAssets.DPI,
    "0x3f382dbd960e3a9bbceae22651e88158d2791550": SupportedAssets.GHST,
    "0x6b3595068778dd592e39a122f4f5a5cf09c90fe2": SupportedAssets.SUSHI,
    "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2": SupportedAssets.WETH,
    "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0": SupportedAssets.WMATIC,
    "0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0": SupportedAssets.WSTETH,
  },
  [SupportedChainId.BSC]: {
    "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3": SupportedAssets.DAI,
    "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d": SupportedAssets.BUSDC,
    "0x55d398326f99059ff775485246999027b3197955": SupportedAssets.BUSDT,
    "0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd": SupportedAssets.LINK,
    "0xfb6115445bff7b52feb98650c87f44907e58f802": SupportedAssets.AAVE,
    "0x2170ed0880ac9a755fd29b2688956bd959f933f8": SupportedAssets.WETH,
    "0xcc42724c6683b7e57334c4e856f4c9965ed682bd": SupportedAssets.WMATIC,
    "0x3ee2200efb3400fabb9aacf31297cbdd1d435d47": SupportedAssets.ADA,
    "0x8ff795a6f4d97e7887c79bea79aba5cc76444adf": SupportedAssets.BCH,
    "0x250632378e573c6be1ac2f97fcdf00515d0aa91b": SupportedAssets.BETH,
    "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c": SupportedAssets.BTCB,
    "0xe9e7cea3dedca5984780bafc599bd69add087d56": SupportedAssets.BUSD,
    "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82": SupportedAssets.CAKE,
    "0x20bff4bbeda07536ff00e073bd8359e5d80d733d": SupportedAssets.CAN,
    "0xba2ae424d960c26247dd6c32edc70b295c744c43": SupportedAssets.DOGE,
    "0x7083609fce4d1d8dc0c979aab8c869ea2c873402": SupportedAssets.DOT,
    "0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153": SupportedAssets.FIL,
    "0x4338665cbb7b2485a8855a139b75d5e34ab0db94": SupportedAssets.LTC,
    "0x156ab3346823b651294766e23e6cf87254d68962": SupportedAssets.LUNA,
    "0x47bead2563dcbf3bf2c9407fea4dc236faba485a": SupportedAssets.SXP,
    "0xce7de646e7208a4ef112cb6ed5038fa6cc6b12e3": SupportedAssets.TRX,
    "0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b": SupportedAssets.TRXOLD,
    "0x40af3827f39d0eacbf4a168f8d4ee67c121d11c9": SupportedAssets.TUSD,
    "0x14016e85a25aeb13065688cafb43044c2ef86784": SupportedAssets.TUSDOLD,
    "0x3d4350cd54aef9f9b2c29435e0fa809957b3f30a": SupportedAssets.UST,
    "0x4bd17003473389a42daf6a0a729f6fdb328bbbd7": SupportedAssets.VAI,
    "0x5f84ce30dc3cf7909101c69086c50de191895883": SupportedAssets.VRT,
    "0xa2e3356610840701bdf5611a53974510ae27e2e1": SupportedAssets.WBETH,
    "0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe": SupportedAssets.XRP,
    "0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63": SupportedAssets.XVS
  },
  [SupportedChainId.POLYGON]: {
    "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063": SupportedAssets.DAI,
    "0x2791bca1f2de4661ed88a30c99a7a9449aa84174": SupportedAssets.USDCE,
    "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359": SupportedAssets.USDC,
    "0xc2132d05d31c914a87c6611c10748aeb04b58e8f": SupportedAssets.USDT,
    "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6": SupportedAssets.WBTC,
    "0x8505b9d2254a7ae468c0e9dd10ccea3a837aef5c": SupportedAssets.COMP,
    "0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39": SupportedAssets.LINK,
    "0xd6df932a45c0f255f85145f286ea0b292b21c90b": SupportedAssets.AAVE,
    "0xe0b52e49357fd4daf2c15e02058dce6bc0057db4": SupportedAssets.AGEUR,
    "0xe111178a87a3bff0c8d18decba5798827539ae99": SupportedAssets.EURS,
    "0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c": SupportedAssets.JEUR,
    "0xa3fa99a148fa48d14ed51d610c367c61876997f1": SupportedAssets.MAI,
    "0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3": SupportedAssets.BAL,
    "0x172370d5cd63279efa6d502dab29171933a610af": SupportedAssets.CRV,
    "0x85955046df4668e1dd369d2de9f3aeb98dd2a369": SupportedAssets.DPI,
    "0x385eeac5cb85a38a9a07a70c73e0a3271cfb54a7": SupportedAssets.GHST,
    "0xfa68fb4628dff1028cfec22b4162fccd0d45efb6": SupportedAssets.MATICX,
    "0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4": SupportedAssets.STMATIC,
    "0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a": SupportedAssets.SUSHI,
    "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619": SupportedAssets.WETH,
    "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270": SupportedAssets.WMATIC,
    "0x51195e21bdae8722b29919db56d95ef51faeca6c": SupportedAssets.VGHST,
    "0x91993f2101cc758d0deb7279d41e880f7defe827": SupportedAssets.GDAI,
    "0x03b54a6e9a984069379fae1a4fc4dbae93b3bccd": SupportedAssets.WSTETH
  },
  [SupportedChainId.MANTLE]: {
    "0x09bc4e0d864854c6afb6eb9a9cdf58ac190d0df9": SupportedAssets.USDC,
    "0x201eba5cc46d216ce6dc03f6a759e8e766e956ae": SupportedAssets.USDT,
    "0xcabae6f6ea1ecab08ad02fe02ce9a44f09aebfa2": SupportedAssets.WBTC,
    "0xdeaddeaddeaddeaddeaddeaddeaddeaddead1111": SupportedAssets.WETH,
    "0x636d4073738c071326aa70c9e5db7c334beb87be": SupportedAssets.WSTETH,
    "0x78c1b0c915c4faa5fffa6cabf0219da63d7f4cb8": SupportedAssets.WMNT,
    "0xcda86a272531e8640cd7f1a92c01839911b90bb0": SupportedAssets.METH,
    "0x25356aeca4210ef7553140edb9b8026089e49396": SupportedAssets.LEND,
    "0x5be26527e817998a7206475496fde1e68957c5a6": SupportedAssets.USDY,
    "0x5d3a1ff2b6bab83b63cd9ad0787074081a52ef34": SupportedAssets.USDE,
    "0xc96de26018a54d51c097160568752c4e3bd6c364": SupportedAssets.FBTC,
    "0x6e20e155819f0ee08d1291b0b9889b0e011b8224": SupportedAssets.OAU,
  },
  [SupportedChainId.TAIKO]: {
    "0xa51894664a773981c6c112c43ce576f315d5b1b6": SupportedAssets.WETH,
    "0xa9d23408b9ba935c230493c40c73824df71a0975": SupportedAssets.TAIKO,
    "0x07d83526730c7438048d55a4fc0b850e2aab6f0b": SupportedAssets.USDC,
    "0x9c2dc7377717603eb92b2655c5f2e7997a4945bd": SupportedAssets.USDTE,
    "0x2def195713cf4a606b49d07e520e22c17899a736": SupportedAssets.USDT,
    "0x19e26b0638bf63aa9fa4d14c6baf8d52ebe86c5c": SupportedAssets.USDCE,
    "0xc4c410459fbaf8f7f86b6cee52b4fa1282ff9704": SupportedAssets.WBTC
  },
  [SupportedChainId.ARBITRUM_ONE]: {
    "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1": SupportedAssets.DAI,
    "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8": SupportedAssets.USDCE,
    "0xaf88d065e77c8cc2239327c5edb3a432268e5831": SupportedAssets.USDC,
    "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9": SupportedAssets.USDT,
    "0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f": SupportedAssets.WBTC,
    "0x82af49447d8a07e3bd95bd0d56f35241523fbab1": SupportedAssets.WETH,
  },
  [SupportedChainId.BASE]: {
    "0x50c5725949a6f0c72e6c4a641f24049a917db0cb": SupportedAssets.DAI,
    "0x2ae3f1ec7f1f5012cfeab0185bfc7aa3cf0dec22": SupportedAssets.CBETH,
    "0x833589fcd6edb6e08f4c7c32d4f71b54bda02913": SupportedAssets.USDC,
    "0x4200000000000000000000000000000000000006": SupportedAssets.WETH,
  },
  [SupportedChainId.AVALANCHE]: {
    "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e": SupportedAssets.USDC,
    "0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7": SupportedAssets.USDT,
    "0x50b7545627a5162f82a992c33b87adc75187b218": SupportedAssets.WBTC,
    "0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab": SupportedAssets.WETH,
    "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7": SupportedAssets.WAVAX,
  },
  [SupportedChainId.OPTIMISM]: {
    "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1": SupportedAssets.DAI,
    "0x7f5c764cbc14f9669b88837ca1490cca17c31607": SupportedAssets.USDCE,
    "0x0b2c639c533813f4aa9d7837caf62653d097ff85": SupportedAssets.USDC,
    "0x94b008aa00579c1307b0ef2c499ad98a8ce58e58": SupportedAssets.USDT,
    "0x68f180fcce6836688e9084f035309e29bf0a2095": SupportedAssets.WBTC,
    "0x4200000000000000000000000000000000000006": SupportedAssets.WETH,
  },
  [SupportedChainId.BLAST]: {
    "0x4300000000000000000000000000000000000003": SupportedAssets.USDB,
    "0xb1a5700fa2358173fe465e6ea4ff52e36e88e2ad": SupportedAssets.BLAST,
    "0xd43d8adac6a4c7d9aeece7c3151fca8f23752cf8": SupportedAssets.ANDY,
    "0x4300000000000000000000000000000000000004": SupportedAssets.WETH,
  },
  [SupportedChainId.LINEA]: {
    "0x4af15ec2a0bd43db75dd04e62faa3b8ef36b00d5": SupportedAssets.DAI,
    "0x176211869ca2b568f2a7d4ee941e073a821ee1ff": SupportedAssets.USDCE,
    "0xa219439258ca9da29e9cc4ce5596924745e12b93": SupportedAssets.USDT,
    "0xe5d7c2a44ffddf6b295a15c148167daaaf5cf34f": SupportedAssets.WETH,
  },
}

/**
 * get asset enum from address
 * @param chainId chainId of token address
 * @param address address
 * @returns asset enum or undefined
 */
export const addressToAsset = (chainId: number, address: string): SupportedAssets => {
  if (address?.toUpperCase() === NATIVE_SYMBOL[chainId]) return NATIVE_ASSET[chainId]
  return ADDRESS_TO_CHAINID_TO_ASSET[chainId]?.[address?.toLowerCase()]
}

/**
 * get native address from chainId
 * @param chainId chainId of token address
 * @returns native address
 */
export const getNativeAddress = (chainId: SupportedChainId) => {
  switch (chainId) {
    case SupportedChainId.POLYGON:
      return '0x0000000000000000000000000000000000001010'
    case SupportedChainId.MANTLE:
      return '0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000'
    default: // otherwise, we do not expect native to be in a list
      return ''
  }
}

/**
 * Converts a token to a SupportedAssets enum
 * @param token input token
 * @returns the corresponding asset / WETH if none is found
 */
export const tokenToAsset = (token?: Currency | undefined): SupportedAssets => {
  if (!token) return SupportedAssets.WETH
  if (token.isNative) {
    switch (token.chainId) {
      case SupportedChainId.MAINNET:
        return SupportedAssets.ETH
      case SupportedChainId.MANTLE:
        return SupportedAssets.MNT
      case SupportedChainId.POLYGON:
        return SupportedAssets.MATIC
      case SupportedChainId.AVALANCHE:
        return SupportedAssets.AVAX
      default:
        return SupportedAssets.ETH
    }
  }
  const _asset = addressToAsset(token.chainId, token.address)
  if (!_asset) return SupportedAssets.WETH
  return _asset
}


export function groupAsset(asset?: string): string {
  if (!asset) return ''
  return TOKEN_GROUPING[asset.toUpperCase()] ?? asset
}

const TOKEN_GROUPING: { [asset: string]: SupportedAssets } = {
  [SupportedAssets.USDC]: SupportedAssets.USDC,
  [SupportedAssets.USDCE]: SupportedAssets.USDC,
  [SupportedAssets.BUSDC]: SupportedAssets.USDC,
  [SupportedAssets.USDT]: SupportedAssets.USDT,
  [SupportedAssets.USDTE]: SupportedAssets.USDT,
  [SupportedAssets.BUSDT]: SupportedAssets.USDT,
  [SupportedAssets.WETH]: SupportedAssets.ETH,
  [SupportedAssets.ETH]: SupportedAssets.ETH,
  [SupportedAssets.MATIC]: SupportedAssets.MATIC,
  [SupportedAssets.WMATIC]: SupportedAssets.MATIC,
  [SupportedAssets.AVAX]: SupportedAssets.AVAX,
  [SupportedAssets.WAVAX]: SupportedAssets.AVAX,
  [SupportedAssets.MNT]: SupportedAssets.MNT,
  [SupportedAssets.WMNT]: SupportedAssets.MNT,
  [SupportedAssets.BNB]: SupportedAssets.BNB,
  [SupportedAssets.WBNB]: SupportedAssets.BNB,
}