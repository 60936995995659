import { Currency, Token } from '@1delta/base-sdk'
import { useCurrencyFromMap, useTokenFromMapOrNetworkAcrossChains } from 'lib/hooks/useCurrency'
import { useMemo } from 'react'
import { useChainId } from 'state/globalNetwork/hooks'

import { useAllMainTokenListsPerChain, useAllTokenListsPerChain } from '../state/lists/hooks'
import { useUserAddedTokens, useUserAddedTokensOnChain } from '../state/user/hooks'
import { getAvailableChainIds } from 'constants/chains'
import { assetToToken } from './1delta/addressesTokens'
import { ChainIdMap } from 'utils/Types'

type TypedTokenMap = { [address: string]: Token }

// reduce token map into standard address <-> Token mapping, optionally include user added tokens
function useTokensFromMap(tokenMap: TypedTokenMap, includeUserAdded: boolean): TypedTokenMap {
  const chainId = useChainId()
  const userAddedTokens = useUserAddedTokens()

  return useMemo(() => {
    if (!chainId) return {}

    // reduce to just tokens
    const mapWithoutUrls = Object.keys(tokenMap ?? {}).reduce<TypedTokenMap>(
      (newMap, address) => {
        newMap[address] = tokenMap[address]
        return newMap
      },
      {}
    )

    if (includeUserAdded) {
      return (
        userAddedTokens
          // reduce into all ALL_TOKENS filtered by the current chain
          .reduce<TypedTokenMap>(
            (tokenMap, token) => {
              tokenMap[token.address.toLowerCase()] = token
              return tokenMap
            },
            // must make a copy because reduce modifies the map, and we do not
            // want to make a copy in every iteration
            { ...mapWithoutUrls }
          )
      )
    }

    return mapWithoutUrls
  }, [chainId, userAddedTokens, tokenMap, includeUserAdded])
}


// reduce token map into standard address <-> Token mapping, optionally include user added tokens
function useTokensFromMapPerChainIds(
  tokenMapPerChain: {
    [chainId: number]: TypedTokenMap
  },
  includeUserAdded: boolean
): { [chainId: number]: TypedTokenMap } {
  const userAddedTokens = useUserAddedTokens()
  return useMemo(() => {
    let map: any = {}
    const chainIds = Object.keys(tokenMapPerChain)
    for (const chainId of chainIds) {
      const tokenMap = tokenMapPerChain[chainId]
      // reduce to just tokens
      const mapWithoutUrls = Object.keys(tokenMap ?? {}).reduce<TypedTokenMap>(
        (newMap, address) => {
          newMap[address] = tokenMap[address]
          return newMap
        },
        {}
      )

      if (includeUserAdded) {
        map[chainId] = (
          userAddedTokens
            // reduce into all ALL_TOKENS filtered by the current chain
            .reduce<TypedTokenMap>(
              (tokenMap, token) => {
                tokenMap[token.address.toLowerCase()] = token
                return tokenMap
              },
              // must make a copy because reduce modifies the map, and we do not
              // want to make a copy in every iteration
              { ...mapWithoutUrls }
            )
        )
      } else {

        map[chainId] = mapWithoutUrls
      }
    }
    return map
  }, [userAddedTokens, tokenMapPerChain, includeUserAdded])
}

export function useAllTokens(_chainId?: number | undefined): TypedTokenMap {
  const chainId = useChainId()
  const chainIdSelected = _chainId ?? chainId
  const allTokens = useAllMainTokenListsPerChain([chainIdSelected])
  return useTokensFromMap(useMemo(() => allTokens?.[chainIdSelected] ?? {}, [allTokens, chainIdSelected]), true)
}

/**
 * Fetch the `mainTokens` from the token lists plus custom tokens
 * @param chainIds list of chains
 * @returns chainId -> Token[]
 */
export function useAllMainTokensPerChain(chainIds: number[]): { [chainId: number]: TypedTokenMap } {
  const allTokens = useAllMainTokenListsPerChain(chainIds)
  return useTokensFromMapPerChainIds(allTokens, true)
}

/**
 * Fetch the `mainTokens` from the token lists plus custom tokens
 * @param chainIds list of chains
 * @returns chainId -> Token[]
 */
export function useAllMainTokensForChain(chainId: number): TypedTokenMap {
  const allTokens = useAllMainTokenListsPerChain([chainId])
  const map = useTokensFromMapPerChainIds(useMemo(() => allTokens, [allTokens]), true)
  return useMemo(() => map?.[chainId], [map, chainId])
}


/**
 * Fetch ALL from the token lists plus custom tokens
 * @param chainIds list of chains
 * @returns chainId -> Token[]
 */
export function useAllTokensPerChain(chainIds: number[]): { [chainId: number]: TypedTokenMap } {
  const allTokens = useAllTokenListsPerChain(chainIds)
  const map = useTokensFromMapPerChainIds(useMemo(() => allTokens, [allTokens]), true)
  return useTokensFromMapPerChainIds(map, true)
}


// Check if currency is included in custom list from user storage
export function useIsUserAddedToken(currency: Currency | undefined): boolean {
  const userAddedTokens = useUserAddedTokens()

  if (!currency) {
    return false
  }

  return !!userAddedTokens.find((token) => {
    try {
      return currency.equals(token)
    } catch (e) {
      return false
    }
  })
}

// Check if currency on specific chain is included in custom list from user storage
export function useIsUserAddedTokenOnChain(
  address: string | undefined | null,
  chain: number | undefined | null
): boolean {
  const userAddedTokens = useUserAddedTokensOnChain(chain)

  if (!address || !chain) {
    return false
  }

  return !!userAddedTokens.find((token) => token.address.toLowerCase() === address.toLowerCase())
}

// undefined if invalid or does not exist
// null if loading or null was passed
// otherwise returns the token
export function useTokenAcrossChains(chains: number[], tokenAddress?: string | null): { [chainId: number]: Token | undefined } {
  const tokens = useAllMainTokensPerChain(chains)
  return useTokenFromMapOrNetworkAcrossChains(chains, tokens, tokenAddress)
}

export function useCurrency(
  currencyId?: string | null
): Currency | undefined {
  const chainId = useChainId()
  const tokens = useAllTokensPerChain([chainId])
  const tokenOnChain = useMemo(() => tokens?.[chainId] ?? {}, [tokens, chainId])
  const ccy = useCurrencyFromMap(tokenOnChain, currencyId)
  // we memo the result based on its main attributes
  return useMemo(() => ccy, [currencyId, ccy?.wrapped.address, ccy?.chainId, ccy?.isNative])
}

export function useCurrencyOnChain(
  chainId: number,
  currencyId?: string | null,
): Currency | undefined {
  const tokens = useAllTokensPerChain([chainId])
  const ccy = useCurrencyFromMap(tokens[chainId], currencyId)
  // we memo the result based on its main attributes
  return useMemo(() => ccy, [currencyId, ccy?.wrapped.address, ccy?.chainId, ccy?.isNative])
}

// get all tokens / native based on a provided assetId
// only gives a mapping for `SupportedAssets` enums, not shitcoins
export function useKnownAssetsOnChain(
  supportedAsset?: string | null,
): ChainIdMap<Currency | undefined> {
  return useMemo(() => {
    const chainIds = getAvailableChainIds()
    if (!supportedAsset) return {}
    let map = {}
    chainIds.map(chainId => {
      const token = assetToToken(supportedAsset.toUpperCase() as any, chainId)
      if (token) map[chainId] = token
    })
    return map
  }, [supportedAsset]
  )
}