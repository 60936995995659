import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { Lender } from 'types/lenderData/base'
import { ChainIdMap } from 'utils/Types'
import { AdditionalYields } from 'state/oracles/reducer'
import { getLenderPublicData } from '../fetchers/fetchLenderData'
import { AaveV2GeneralPublicResponse } from '../fetchers/aave-v2-type/types'

interface AaveV2ReservesQueryParams {
  chainId: number
}

export const fetchAaveV2PublicData: AsyncThunk<AaveV2GeneralPublicResponse, AaveV2ReservesQueryParams, any> =
  createAsyncThunk<
    AaveV2GeneralPublicResponse,
    AaveV2ReservesQueryParams,
    {
      state: {
        oracles: {
          live: ChainIdMap<number>
          additionalYields: AdditionalYields
        }
      },
    }
  >(
    'aave-v2/fetchAaveV2PublicData',
    async ({ chainId }, { getState }) => {
      // get fields from other slices
      const prices = getState().oracles.live
      const additionalYields = getState().oracles.additionalYields
      try {
        const lenderData = await getLenderPublicData(chainId, [Lender.AAVE_V2], prices, additionalYields)
        return lenderData[Lender.AAVE_V2]
      } catch (error) {
        console.error('Error fetching Aave V2 public data:', error)
        return {
          data: {},
          config: {},
          chainId,
        }
      }
    }
  )