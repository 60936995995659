import { NativeCurrency, Token } from '@1delta/base-sdk'
import { STATE_CHAINIDS, SupportedChainId } from 'constants/chains'
import { nativeOnChain } from 'constants/tokens'
import { useMemo } from 'react'
import { useChainId } from 'state/globalNetwork/hooks'
import { ChainIdMap } from 'utils/Types'

export default function useNativeCurrency(): NativeCurrency | Token {
  const chainId = useChainId()
  return useMemo(
    () =>
      chainId
        ? nativeOnChain(chainId)
        : // display mainnet when not connected
        nativeOnChain(SupportedChainId.MAINNET),
    [chainId]
  )
}


export function useNativeCurrencies(): ChainIdMap<NativeCurrency | Token> {
  return useMemo(
    () => Object.assign({}, ...STATE_CHAINIDS.map(chainId => {
      return {
        [chainId]: nativeOnChain(chainId)
      }
    })
    ),
    []
  )
}
