import { Currency, CurrencyAmount } from '@1delta/base-sdk'
import { nativeOnChain } from 'constants/tokens'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../index'
import { setChainId, setAccount, setImpersonatedAccount, setUseImpersonatedAccount, setSubscribeConfigXChain } from './actions'
import { SupportedAssets } from 'types/1delta'
import { getTokenFromAsset } from 'hooks/1delta/tokens'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { isAddress } from 'utils'
import { SubscribeConfig } from './reducer'
import { useWeb3ReactWrapped } from 'hooks/web3'
import { useAllTokens, useAllMainTokensPerChain } from 'hooks/Tokens'
import { getDefaultGasConfig } from 'constants/gas'
import { ChainIdMap } from 'utils/Types'
import { fetchAllBalances, GasData } from './fetchAllBalanceData'
import { useAllUserAddedTokens, useUserAddedTokens } from 'state/user/hooks'

export function useNetworkState() {
  const { account, impersontedAccount, useImpersonatedAccount, ...rest } = useAppSelector((state) => state.globalNetwork)
  if (impersontedAccount) return { account: impersontedAccount, ...rest }
  return { account, ...rest }
}

export function useChainId(): number {
  return useSelector<AppState, AppState['globalNetwork']>((state) => state.globalNetwork).chainId
}

export function useAccount(): string | undefined {
  const { account, impersontedAccount, useImpersonatedAccount } = useAppSelector((state) => state.globalNetwork)
  if (useImpersonatedAccount) return impersontedAccount
  return account
}

export function useGasData(chainId: number): GasData {
  return useAppSelector((state) => state.globalNetwork.networkData[chainId]?.gasData) ?? getDefaultGasConfig(chainId)
}

export function useIsImpersonated() {
  const { useImpersonatedAccount, impersontedAccount } = useAppSelector((state) => state.globalNetwork)
  return { useImpersonatedAccount, impersontedAccount }
}

export function useChainIdAndAccount(): { chainId: number, account: string | undefined } {
  const state = useSelector<AppState, AppState['globalNetwork']>((state) => state.globalNetwork)
  return { chainId: state.chainId, account: state.account }
}

export function useSubscribeConfig(chainId: number | undefined): SubscribeConfig {
  return useAppSelector((state) => state.globalNetwork.subscribeConfigs[chainId ?? 0])
}

export function useSubscribeConfigs(): ChainIdMap<SubscribeConfig> {
  return useAppSelector((state) => state.globalNetwork.subscribeConfigs)
}

export function useAllTokenAddresses(): string[] {
  const allTokens = useAllTokens()
  return useMemo(() => Object.keys(allTokens), [allTokens])
}

export function useAllTokenAddressesPerChain(chainIds: number[]): ChainIdMap<string[]> {
  const allTokens = useAllMainTokensPerChain(chainIds)
  const userAddedTokens = useAllUserAddedTokens()
  return useMemo(() => Object.assign({},
    ...chainIds.map(id => {
      const stateData = Object.keys(allTokens[id] ?? {})
      const userAdded = userAddedTokens.filter(t => t.chainId === id).map(a => a.address)
      return { [id]: [...stateData, ...userAdded] }
    })),
    [allTokens, userAddedTokens])
}

export function useRefreshNetworkData(account?: string) {
  const dispatch = useAppDispatch()
  return useCallback((assetIn?: Currency, assetOut?: Currency) => {
    // we only force a refresh if connected
    if (account) {
      let tokens: any = {}
      let chainIdsToQuery: number[] = []
      if (assetIn) {
        if (!assetIn.isNative) tokens[assetIn.chainId] = [assetIn.address]
        chainIdsToQuery = [assetIn.chainId]
      }
      if (assetOut) {
        if (!tokens[assetOut.chainId]) tokens[assetOut.chainId] = []
        if (assetOut.chainId === assetIn?.chainId) {
          chainIdsToQuery = [assetOut.chainId]
        } else {
          chainIdsToQuery.push(assetOut.chainId)
        }
        if (!assetOut.isNative) tokens[assetOut.chainId].push(assetOut.address)
      }
      dispatch(fetchAllBalances({ chainIdsToQuery, tokens }))
    }
  },
    [dispatch, account]
  )
}

export function useSetSingleSubscribeConfigXChain() {
  const dispatch = useAppDispatch()
  return useCallback((chainId: number, config: SubscribeConfig) => {
    dispatch(setSubscribeConfigXChain({ [chainId]: config }))
  },
    [dispatch]
  )
}

export function useSetSubscribeConfigXChain() {
  const dispatch = useAppDispatch()
  return useCallback((configs: ChainIdMap<SubscribeConfig>) => {
    dispatch(setSubscribeConfigXChain(configs))
  },
    [dispatch]
  )
}

/**
 * Read subscribed token / native balances
 * Make sure to add them to the subscribe config before
 * @param chainId network
 * @param currencies assets
 * @returns currency balance array
 */
export function useSubscribedCurrencyBalances(chainId: number, currencies: (Currency | undefined)[]): {
  balances: (CurrencyAmount<Currency> | undefined)[], isLoading: boolean
} {
  const { account } = useWeb3ReactWrapped()
  const data = useAppSelector((state) => state.globalNetwork.networkData[chainId].tokenData)
  const nativeData = useAppSelector((state) => state.globalNetwork.networkData[chainId].nativeBalance)
  return useMemo(() => {
    if (currencies.length === 0) return { balances: [], isLoading: false }
    if (!account) return { balances: currencies.map((ccy) => ccy && CurrencyAmount.fromRawAmount(ccy, "0")), isLoading: false }
    let isLoading = false
    return {
      balances: currencies.map(ccy => {
        if (!ccy) return undefined
        if (ccy.isNative) return nativeData ? CurrencyAmount.fromRawAmount(ccy, nativeData) : undefined
        const ccyData = data[ccy.wrapped.address.toLowerCase()]?.balance
        if (!ccyData) isLoading = true
        return CurrencyAmount.fromRawAmount(ccy, ccyData ?? "0")
      }), isLoading: isLoading || nativeData === undefined
    }
  }, [currencies, account, data])
}

/**
 * Read subscribed token / native balances
 * Make sure to add them to the subscribe config before
 * @param chainId network
 * @param currencies assets
 * @returns currency balance array
 */
export function useSubscribedXChainCurrencyBalances(currencies: (Currency | undefined)[]): {
  balances: (CurrencyAmount<Currency> | undefined)[],
  isLoading: boolean,
} {
  const { account } = useWeb3ReactWrapped()
  const data = useAppSelector((state) => state.globalNetwork.networkData)
  return useMemo(() => {
    if (currencies.length === 0) return { balances: [], isLoading: false }
    if (!account) return { balances: currencies.map((ccy) => ccy && CurrencyAmount.fromRawAmount(ccy, 0n)), isLoading: false }
    let isLoading = false
    return {
      balances: currencies.map(ccy => {
        if (!ccy || !ccy.decimals) return undefined
        const chainId = ccy.chainId
        if (ccy.isNative) {
          const nativeData = data[chainId].nativeBalance ?? 0n
          return CurrencyAmount.fromRawAmount(ccy, nativeData)
        }
        const ccyData = data[chainId]?.tokenData?.[ccy?.wrapped.address.toLowerCase()]?.balance ?? 0n
        return CurrencyAmount.fromRawAmount(ccy, ccyData)
      }), isLoading: isLoading
    }
  }, [currencies, account, data])
}



type AllowaneData = { [target: string]: (CurrencyAmount<Currency> | undefined) }
const NO_DATA = {}
export function useSubscribedAllowances(chainId: number | undefined, currencies: (Currency | undefined)[]): AllowaneData[] {
  const data = useAppSelector((state) => state.globalNetwork.networkData[chainId ?? 0]?.tokenData)
  if (!data) return currencies.map(_ => NO_DATA)
  if (currencies.length === 0) return []
  return currencies.map(
    ccy => {
      if (!ccy) return NO_DATA
      if (ccy.isNative) return NO_DATA
      const ccyData = data[ccy.wrapped.address.toLowerCase()]?.allowanceData
      if (!ccyData?.allowance || !ccyData?.target) return NO_DATA
      const targets = Object.keys(ccyData)
      return Object.assign(
        {}, ...targets.map(t =>
          CurrencyAmount.fromRawAmount(ccy, ccyData[t]?.allowance ?? '')
        )
      )
    }
  )
}

/**
 * Setter for impersonated account
 * @returns setter for account and disabler
 */
export const useSetImpersonatedAccount = () => {
  const dispatch = useAppDispatch()

  const setAccount = useCallback((account: string | undefined) => {
    const address = isAddress(account)
    if (address) {
      dispatch(setImpersonatedAccount({ account }))
      dispatch(setUseImpersonatedAccount({ isUsed: true }))
    }
  }, [])

  const disableAccount = useCallback(() => {
    dispatch(setUseImpersonatedAccount({ isUsed: false }))
  }, [])
  return { setAccount, disableAccount }
}

/**
 * Get flag as to whether connection is supported
 * @returns true if supported, false if not
 */
export function useIsSupported(): boolean {
  return useSelector<AppState, AppState['globalNetwork']>((state) => state.globalNetwork).connectionIsSupported
}

export function useTimestamp(): string {
  const chainId = useChainId()
  return useSelector<AppState, AppState['globalNetwork']>((state) => state.globalNetwork).networkData[chainId].lastTimestamp
}

/**
 * Get raw native balance as serialized BigNumber
 * @returns unformatted native balance, undefined if not connected
 */
export function useNativeBalance(): string | undefined {
  const chainId = useChainId()
  return useSelector<AppState, AppState['globalNetwork']>((state) => state.globalNetwork).networkData[chainId].nativeBalance
}

/**
 * Get the native balance for connected account - defaults to 0 if not connected
 * @returns native balance as CurrencyAmount object
 */
export function useNativeCurrencyBalance(): CurrencyAmount<Currency> {
  const chainId = useChainId()
  return CurrencyAmount.fromRawAmount(nativeOnChain(chainId), useSelector<AppState, AppState['globalNetwork']>(
    (state) => state.globalNetwork
  ).networkData[chainId].nativeBalance ?? '0'
  )
}

/**
 * Get the block number from state
 * @returns block number as number object
 */
export function useBlockNumber(): number {
  const chainId = useChainId()
  return useSelector<AppState, AppState['globalNetwork']>((state) => state.globalNetwork).networkData[chainId]?.blockNumber
}

/**
 * Get the block number from state
 * @returns block number as number object
 */
export function useBlockNumbers(chainIds: number[]): Record<number, number> {
  const data = useSelector<AppState, AppState['globalNetwork']>((state) => state.globalNetwork).networkData
  return Object.assign({}, ...chainIds.map(chainId => { return { [chainId]: data[chainId]?.blockNumber } }))
}


export function useGlobalNetworkActionHandlers(): {
  onChainChange: (chainId: number) => void
  onAccountChange: (account: string) => void
} {
  const dispatch = useDispatch<AppDispatch>()

  const onChainChange = useCallback(
    (chainId: number) => {
      dispatch(setChainId({ chainId }))
    },
    [dispatch]
  )
  const onAccountChange = useCallback(
    (account: string) => {
      dispatch(setAccount({ account }))
    },
    [dispatch]
  )
  return {
    onChainChange,
    onAccountChange,
  }
}


/**
 * Get the native balance for connected account - defaults to 0 if not connected
 * @returns native balance as CurrencyAmount object
 */
export function useTokenBalances(assets: (SupportedAssets | undefined)[]): (CurrencyAmount<Currency> | undefined)[] {
  const chainId = useChainId()
  const balances = useSelector<AppState, AppState['globalNetwork']>((state) => state.globalNetwork).networkData[chainId].tokenData
  return assets.map(a => {
    if (!a) return undefined
    const token = getTokenFromAsset(a, chainId)
    if (!token) return undefined
    const balance = balances[token?.address.toLowerCase()].balance ?? 0n
    if (!token || balance === undefined) return undefined
    return CurrencyAmount.fromRawAmount(token, balance)
  }
  )
}

/**
 * Get flag whether the wallet data is changing
 * @returns boolean
 */
export function useIsLoading() {
  return useAppSelector(state => state.globalNetwork.loading)
}