import { Tab, TabList, TabPanel, TabPanels, TabProps, TabPanelProps, Tabs, Flex, Text, Badge } from '@chakra-ui/react'
import { validateColor } from 'theme/colors'
import { getTypography } from 'theme/typographies'
import { WalletMenuAssets } from './WalletMenuAssets'
import { Transactions } from 'components/Menu/Notifications/Transactions'
import { useTransactionHistoryV2 } from 'components/Menu/Notifications/hooks/useTransactionHistory'
import { Updates } from 'components/Menu/Notifications/Updates'
import { FadeInAnimation } from 'components/PageLayout'

const CustomTab: React.FC<TabProps> = ({ ...props }) => {
  let selectedStyle = getTypography('Typography/Small/Bold/Small 1')
  // attach !important to all rules inside selectedStyle
  selectedStyle = Object.keys(selectedStyle).reduce((acc, key) => {
    acc[key] = `${selectedStyle[key]} !important`
    return acc
  }, {} as Record<string, string>)
  // remove lineHeight from selectedStyle
  delete selectedStyle.lineHeight

  return (
    <Tab
      p="0"
      py="0.5rem"
      color={validateColor('Navigation/Navigation-text-inactive')}
      style={getTypography('Typography/Small/Normal/Small 1')}
      _selected={{
        color: validateColor('Navigation/Navigation-text-active'),
        ...selectedStyle,
      }}
      lineHeight={'0.8 !important'}
      {...props}
    />
  )
}

const CustomTabPanel: React.FC<TabPanelProps> = ({ ...props }) => {
  return <TabPanel p="0" {...props} />
}

interface WalletTabsProps {
  account: string
  chainId: number,
  setTotalBalance: (n: number) => void
}

export const WalletTabs: React.FC<WalletTabsProps> = ({ account, chainId, setTotalBalance }) => {
  const { pending, confirmedTransactions, clearAllTransactionsCallback } = useTransactionHistoryV2()

  const showNotificationBadge = pending.length > 0

  return (
    <Tabs variant="unstyled" w="100%">
      <TabList gap="1rem" mb="1rem">
        <CustomTab>Balances</CustomTab>
        <CustomTab>
          <Flex mr={showNotificationBadge ? '-8px' : '0'}>
            <Text>Activity</Text>
            {showNotificationBadge && (
              <Badge
                bg={validateColor('Text/Lables/Lable-error')}
                borderRadius="full"
                w="0.5rem"
                h="0.5rem"
                position="relative"
                top="-8px"
                right="2px"
              />
            )}
          </Flex>
        </CustomTab>
        {/* <CustomTab>Notifications</CustomTab> */}
      </TabList>
      <TabPanels>
        <CustomTabPanel css={FadeInAnimation}>
          <WalletMenuAssets account={account} chainId={chainId} setTotalBalance={setTotalBalance} />
        </CustomTabPanel>
        <CustomTabPanel css={FadeInAnimation}>
          <Transactions pending={pending} confirmedTransactions={confirmedTransactions} />
        </CustomTabPanel>
        {/* <CustomTabPanel css={FadeInAnimation}>
          <Updates />
        </CustomTabPanel> */}
      </TabPanels>
    </Tabs>
  )
}
