import { ChainTokenMap } from 'lib/hooks/useTokenList/utils'
import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { AppState } from '../index'
import { Token } from '@1delta/base-sdk'
import { TypedTokenList, TypedTokenListPerChain } from './reducer'
import { fetchXChainTokens } from './fetchXChainTokens'
import { ChainIdMap } from 'utils/Types'

export type TokenAddressMap = ChainTokenMap

export function useAllLists(): AppState['lists']['byChain'] {
  return useAppSelector((state) => state.lists.byChain)
}

function useAdditionalTokens(): AppState['lists']['tokens'] {
  return useAppSelector((state) => state.lists.tokens)
}

export function useDeltaTokenList(chainId: number): TypedTokenList {
  const rawList = useAppSelector((state) => state.lists?.byChain?.[chainId]?.currentList)
  const addressesToSeek = rawList?.mainTokens ?? []
  return useMemo(() => {
    if (!rawList || !rawList?.list) return {}
    let list: TypedTokenList = {}
    addressesToSeek.forEach(addr => {
      const lowAddr = addr.toLowerCase()
      const entr = rawList.list[lowAddr]
      if (entr && entr.chainId === chainId) {
        list[lowAddr] = new Token(chainId, entr.address as any, Number(entr.decimals ?? 0), entr.symbol, entr.name)
      }
    });
    return list
  }, [rawList])
}

export function useAllTokenLists(): TypedTokenList {
  const allLists = useAllLists()
  const chainIds = Object.keys(allLists)
  const map: TypedTokenList = {}
  chainIds.forEach(chainId => {
    const list = useDeltaTokenList(Number(chainId))
    Object.keys(list).forEach(addr => {
      map[addr] = list[addr]
    })
  })
  return map
}

export function useAllMainTokenListsPerChain(chainIds: number[]): TypedTokenListPerChain {
  const allLists = useAllLists()
  const additonals = useAdditionalTokens()
  return useMemo(() => {
    const map: TypedTokenListPerChain = {}
    chainIds.forEach(chainId => {
      const list = allLists[Number(chainId)]?.currentList?.list
      const mainTokens = allLists[Number(chainId)]?.currentList?.mainTokens
      map[chainId] = {}
      // map additionals
      Object.values(additonals[chainId] ?? []).forEach(entr => {
        if (entr) {
          const key = entr.address.toLowerCase()
          map[chainId][key] = new Token(chainId, entr.address as any, Number(entr.decimals ?? 0), entr.symbol, entr.name)
        }
      });
      // map lists
      mainTokens?.forEach(addr => {
        const key = addr.toLowerCase()
        const entr = list?.[key]
        if (entr) map[chainId][key] = new Token(chainId, entr.address as any, Number(entr.decimals ?? 0), entr.symbol, entr.name)
      })
    })
    return map
  },
    [allLists, additonals, chainIds])
}

export function useAllTokenListsPerChain(chainIds: number[]): TypedTokenListPerChain {
  const allLists = useAllLists()
  const additonals = useAdditionalTokens()
  return useMemo(() => {
    const map: TypedTokenListPerChain = {}
    chainIds.forEach(chainId => {
      const list = allLists[Number(chainId)]?.currentList?.list
      map[chainId] = {}
      // map additionals
      Object.values(additonals[chainId] ?? []).forEach(entr => {
        if (entr) {
          const key = entr.address.toLowerCase()
          map[chainId][key] = new Token(chainId, entr.address as any, Number(entr.decimals ?? 0), entr.symbol, entr.name)
        }
      });
      // map lists
      Object.values(list ?? {}).forEach(entr => {
        const key = entr.address?.toLowerCase()
        if (key) map[chainId][key] = new Token(chainId, entr.address as any, Number(entr.decimals ?? 0), entr.symbol, entr.name)
      })
    })
    return map
  },
    [allLists, additonals, chainIds])
}


/**
 * Fetch tokens from chain across provided chainIds
 * Make sure that the token is NOT in any list before using this!
 * @param chainIds chainId array
 * @param token token address
 * @returns { [chainId:number]: Token }
 */
export function useTokensFromChain(chainIds: number[], token: string | undefined, forceFetch = false) {
  const dispatch = useAppDispatch()
  const tokensFromState = useAppSelector((state) => state.lists.tokens)

  useEffect(() => {
    if (token) {
      dispatch(fetchXChainTokens({ tokenAdddress: token, chainIds, forceFetch }))
    }
  }, [token, forceFetch])


  return useMemo(() => {
    let tokens: ChainIdMap<Token> = {}
    if (!token) return tokens
    for (const chainId of chainIds) {
      const tokenFetched = tokensFromState[chainId][token.toLowerCase()]
      if (tokenFetched) {
        tokens[chainId] = new Token(
          chainId,
          tokenFetched.address as any,
          tokenFetched.decimals,
          tokenFetched.symbol,
          tokenFetched.name
        )
      }
    }
    return tokens
  }, [tokensFromState, token])
}