import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'
import { Lender } from 'types/lenderData/base'
import { ChainIdMap } from 'utils/Types'
import { AdditionalYields } from 'state/oracles/reducer'
import { getLenderPublicData } from '../fetchers/fetchLenderData'
import { CompoundV3GeneralPublicResponse } from '../fetchers/compound-v3/types'

interface CompoundV3ReservesQueryParams {
  chainId: number
}

export const fetchCompoundV3PublicData: AsyncThunk<CompoundV3GeneralPublicResponse, CompoundV3ReservesQueryParams, any> =
  createAsyncThunk<
    CompoundV3GeneralPublicResponse,
    CompoundV3ReservesQueryParams,
    {
      state: {
        oracles: {
          live: ChainIdMap<number>
          additionalYields: AdditionalYields
        }
      },
    }
  >(
    'compound-v3/fetchCompoundV3PublicData',
    async ({ chainId }, { getState }) => {
      // get fields from other slices
      const prices = getState().oracles.live
      const additionalYields = getState().oracles.additionalYields
      try {
        const lenderData = await getLenderPublicData(chainId, [Lender.COMPOUND_V3], prices, additionalYields)
        return lenderData[Lender.COMPOUND_V3]
      } catch (error) {
        console.error('Error fetching Compound V3 public data:', error)
        return {
          data: {},
          chainId,
        }
      }
    }
  )